import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useRoutes, useSearchParams } from 'react-router-dom';
import { useIntercom } from 'react-use-intercom';

import { preloadApp } from 'src/features/init/actions';
import Init from 'src/features/init/components';
import { initSelector } from 'src/features/init/slices';
import { useAnalytics } from 'src/helpers/analytics';

import {
  Feature,
  getUserIdentity,
  useFeatureFlag,
  useFeatureFlagClient,
} from 'src/helpers/featureFlags';
import { mainRoutesConfig, modalRoutesConfig } from './config';

const AppRouter = () => {
  const client = useFeatureFlagClient();
  const dispatch = useDispatch();
  const { update: intercomUpdate } = useIntercom();
  const init = useSelector(initSelector);
  const location = useLocation();
  const state = location.state as { backgroundLocation?: Location };
  const { isInitializing, user } = init;

  const [searchParams, setSearchParams] = useSearchParams();
  const [isLDLoaded, setLDLoaded] = React.useState(false);
  const { identify, disableCapture, enableCapture } = useAnalytics();
  const getFeatureValue = useFeatureFlag();
  const isAnalyticsDisabled = getFeatureValue(
    Feature.DISABLE_POSTHOG_ANALYTICS,
    true,
  );

  // Filter routes dynamically based on user role
  const getFilteredRoutes = () => {
    if (!user || !user.roles) return mainRoutesConfig;

    const isAgencyServiceMemberRole = user?.roles?.agencyRoles?.some(
      (role) => role.role === 'isAgencyServiceMemberRole',
    );

    if (isAgencyServiceMemberRole) {
      // Exclude /applications routes for service role
      return mainRoutesConfig.filter(
        (route) => !route.path.startsWith('/applications'),
      );
    }

    return mainRoutesConfig;
  };

  const filteredRoutes = getFilteredRoutes();
  const appRoutes = useRoutes(
    filteredRoutes,
    state?.backgroundLocation || location,
  );
  const modalRoutes = useRoutes(modalRoutesConfig);

  // Handle UTM params
  React.useEffect(() => {
    const utmCookies: string[] = [];
    const nonUtmParams: Record<string, string> = {};

    // Create a cookie from the URL's query params
    for (const entry of searchParams.entries()) {
      const [param, value] = entry;
      if (param.startsWith('utm_')) {
        utmCookies.push(`${param}=${value};`);
      } else {
        nonUtmParams[param] = value;
      }
    }

    // Don't set cookies if no utm params
    if (utmCookies.length === 0) {
      return;
    }

    // Clear UTM params
    setSearchParams(nonUtmParams);

    // Expire after a week
    const timestamp = new Date().getTime(); // Current time
    const exp = timestamp + 60 * 60 * 24 * 1000 * 7;

    utmCookies.forEach((cookie) => {
      document.cookie = `${cookie} Domain=nirvanatech.com; Path=/; expires=${exp}`;
    });
  }, [searchParams, setSearchParams]);

  React.useEffect(() => {
    dispatch(preloadApp());
  }, [dispatch]);

  React.useEffect(() => {
    if (user && user.id) {
      intercomUpdate({ name: user.name, email: user.email, userId: user.id });

      // Do not identity internal users and disable capturing
      if (isAnalyticsDisabled) {
        disableCapture();
      } else {
        enableCapture();
        identify(user);
      }
    }
  }, [user, identify, disableCapture, enableCapture, isAnalyticsDisabled]);

  React.useEffect(() => {
    if (!isInitializing) {
      if (user && user.id) {
        client?.identify(getUserIdentity(user)).then(() => {
          setLDLoaded(true);
        });
      } else {
        setLDLoaded(true);
      }
    }
  }, [client, isInitializing, user]);

  if (isInitializing || !isLDLoaded) {
    return <Init />;
  }

  return (
    <>
      {appRoutes}
      {state?.backgroundLocation && modalRoutes}
    </>
  );
};

export default AppRouter;
