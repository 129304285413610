import { Button } from '@material-ui/core';
import { Dialog } from '@nirvana/ui-kit';
import React from 'react';

interface CancelEndorsementModalProps {
  open: boolean;
  onClose: () => void;
  onConfirm: () => void;
}

const CancelEndorsementModal: React.FC<CancelEndorsementModalProps> = ({
  open,
  onClose,
  onConfirm,
}) => {
  return (
    <Dialog
      open={open}
      onClose={onClose}
      maxWidth="sm"
      PaperProps={{ style: { width: '36rem' } }}
      title={
        <p className="text-sm font-bold text-text-primary">
          Cancel Endorsement
        </p>
      }
      fullWidth
      primaryAction={
        <Button onClick={onConfirm} color="primary" variant="contained">
          Cancel Endorsement
        </Button>
      }
      secondaryAction={
        <Button onClick={onClose} color="primary" variant="outlined">
          Back
        </Button>
      }
    >
      <hr className="-mx-6 -mt-6 border-gray-300" />

      <div className="my-4 text-sm text-text-primary">
        Are you sure you want to cancel this endorsement? Canceled endorsements
        cannot be reopened and will require you to submit a new request.
      </div>
    </Dialog>
  );
};

export default CancelEndorsementModal;
