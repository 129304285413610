import { GetInsuranceBundleResponse } from '@nirvana/api/endorsementapp';
import { Chip, formatNumber, getFormattedDate } from '@nirvana/ui-kit';
import SuccessIcon from 'src/assets/icons/check-circle-sm.svg?react';
import PolicyCube from 'src/assets/icons/policy-cube.svg?react';

type SummaryCardProps = {
  data: GetInsuranceBundleResponse | undefined;
};

export default function SummaryCard({ data }: SummaryCardProps) {
  return (
    <section className="relative min-w-[436px] max-w-xl p-6 mb-10 bg-white rounded-lg border-text-darkGrey flex-1 border-2">
      <p className="absolute font-semibold top-6 right-6 text-text-primary">
        {data?.packageType} Plan
      </p>

      <div className="flex items-start mb-5">
        <div className="flex items-center justify-center mr-3 bg-[#ECF7FF] px-3 py-2 rounded-md">
          <PolicyCube />
        </div>

        <div>
          <p className="text-text-hint">Policy Number</p>
          <div className="flex items-center space-x-3">
            <p className="text-xl font-bold">
              {data?.policyNumber.replace(/^\D+/g, '')}
            </p>
            <Chip color="success" icon={<SuccessIcon />} label={data?.status} />
          </div>
        </div>
      </div>

      <div className="flex justify-between mb-5">
        <p className="space-y-1 text-xs text-text-hint">
          <span className="block">Annual Gross</span>
          <span className="block">Written Premium</span>
        </p>

        <p className="font-bold text-[28px] text-secondary-main">
          <span className="text-text-hint">$</span>
          {!!data && formatNumber(data.totalPremium)}
        </p>
      </div>

      <div className="flex justify-between">
        <p className="text-xs text-text-hint">Effective Date</p>
        <p className="font-medium text-text-primary">
          {!!data && getFormattedDate(data.effectiveDate)}
        </p>
      </div>

      <div className="flex justify-between ">
        <p className="text-xs text-text-hint">Expiration Date</p>
        <p className="font-medium text-text-primary">
          {!!data && getFormattedDate(data.effectiveDateTo)}
        </p>
      </div>
    </section>
  );
}
