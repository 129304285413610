import { TSP } from '@nirvana/api/quoting';

export const getLogoDimensions = (tsp: TSP) => {
  switch (tsp) {
    case TSP.TspGeotab:
      return { width: '180px' };

    default:
      return { height: '60px' };
  }
};

export const getCredentialsUsageHelperText = (tsp: TSP) => {
  switch (tsp) {
    case TSP.TspGeotab:
      return 'Please ensure appropriate user security clearance permissions (administrator, supervisor or view only) with no groups restrictions.';

    default:
      return '';
  }
};

export const getForgotPasswordLink = (tsp: TSP) => {
  switch (tsp) {
    case TSP.TspBigRoad:
      return 'https://bigroad.com/blog/your-top-support-questions-answered/#:~:text=Forgot%20Your%20Password?,and%20email%20it%20to%20me.%E2%80%9D';

    case TSP.TspZonar:
      return 'https://partners.zonarsystems.com/user/forgotpassword';

    case TSP.TspFleetComplete:
      return 'https://web.fleetcomplete.com/login/forgotpassword';

    case TSP.TspGeotab:
      return 'https://my.geotab.com/resetPassword.html';

    case TSP.TspVerizonConnect:
      return 'https://reveal.fleetmatics.com/login.aspx';

    case TSP.TspVerizonConnectFleet:
      return 'https://login.platform.telogis.com/';

    case TSP.TspVerizonConnectReveal:
      return 'https://reveal.fleetmatics.com/login.aspx';

    case TSP.TspMountainEld:
      return 'https://app.mountaineld.com/home/recoverypassword';

    case TSP.Tsptfmeld:
      return 'https://app.tfmeld.com/home/recoverypassword';

    case TSP.Tspkskeld:
      return 'https://app.kskeld.com/home/recoverypassword';

    case TSP.TspgpsTab:
      return 'https://app.gpstab.com/home/recoverypassword';

    case TSP.TspMasterEld:
      return 'https://app.mastereld.com/home/recoverypassword';

    case TSP.TspRightTruckingEld:
      return 'https://app.righttruckdeal.com/home/recoverypassword';

    case TSP.TspRoadStarEld:
      return 'https://app.brightroadstar.com/home/recoverypassword';
    case TSP.TspOptimaEld:
      return 'https://web.optimaeld.com/#/auth/password/forgot';

    case TSP.Tspxeld:
      return 'https://cloud.xeld.us/#/auth/password/forgot';

    case TSP.TspProRideEld:
      return 'https://web.prorideeld.com/#/auth/password/forgot';

    case TSP.TspRealEld:
      return 'https://app.realeld.com/home/recoverypassword';

    case TSP.Tsptteld:
      return 'https://dash.tteld.com/#/auth/reset_password';

    case TSP.TspOmnitracs:
    case TSP.TspOmnitracsXrs:
      return 'https://services.omnitracs.com/portalWeb/forgotPassword.do';

    case TSP.Tsperoad:
      return 'https://my.eroad.com/forgot-password';

    case TSP.TspTransflo:
      return 'https://one.transflo.com/auth/resetpassword?email=';

    case TSP.TspgoFleet:
      return 'https://gofleet.geotab.com/resetPassword.html';

    case TSP.TspgoGps:
      return 'https://gogps.geotab.com/resetPassword.html';

    case TSP.TspAgilisLinxup:
      return 'https://www.linxup.com/password.html';

    case TSP.TspatAndTFleet:
      return 'https://afmfe.att.com/resetPassword.html';

    case TSP.TspFleetistics:
      return 'https://fleetistics.geotab.com/resetPassword.html';

    case TSP.TspenVueTelematics:
      return 'https://envuetelematics.geotab.com/resetPassword.html';

    case TSP.TspContiGo:
      return 'https://app.conti-gotech.com/home/recoverypassword';

    case TSP.TspZippyEld:
      return 'https://dash.zippyeld.com/#/auth/reset_password';

    case TSP.Tspevoeld:
      return 'https://dash.evoeld.com/#/auth/reset_password';

    case TSP.Tspideld:
      return 'https://app.ideld.com/home/recoverypassword';

    default:
      return '';
  }
};

export const getLoginIdFieldHelperText = (tsp: TSP) => {
  switch (tsp) {
    case TSP.TspOmnitracs:
    case TSP.TspOmnitracsXrs:
      return 'This field is mandatory only for XRS customers, and optional for ES customers. Include it if you normally use a Company ID to log in.';

    default:
      return '';
  }
};

export const getLoginIdHelpUrl = (tsp: TSP) => {
  switch (tsp) {
    case TSP.TspOmnitracs:
    case TSP.TspOmnitracsXrs:
      return 'https://intercom.help/nirvana-insurance/en/articles/8955956-navigating-omnitracs-credentials';

    default:
      return '';
  }
};
