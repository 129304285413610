import {
  FormControl,
  FormHelperText,
  MenuItem,
  Select,
  Typography,
} from '@material-ui/core';
import {
  Checkbox,
  InputWithLabel,
  InputZip,
  Show,
  constants,
} from '@nirvana/ui-kit';
import { useMutation, useQuery } from '@tanstack/react-query';
import { useState } from 'react';
import { Controller, useFormContext } from 'react-hook-form-v7';
import { useParams } from 'react-router-dom';
import AddressSearch from 'src/components/address-search';
import { decodeZipInfo } from 'src/features/admitted/queries/application';
import { fetchMiscEndorsement } from 'src/features/policy/queries/endorsement';

const TerminalAddress = () => {
  const { mutateAsync } = useMutation(decodeZipInfo);
  const [isSameAsMailingAddress, setIsSameAsMailingAddress] = useState(true);
  const { policyId = '', endorsementId = '' } = useParams();

  const {
    control,
    formState: { errors },
    register,
    setValue,
  } = useFormContext();

  const { data } = useQuery(
    ['endorsement-misc'],
    () =>
      fetchMiscEndorsement({
        policyId,
        endorsementId,
      }),
    {
      onSuccess: (data) => {
        const { mailingAddress, terminalAddress } = data;

        // If terminal address is same as mailing address
        const serializedMailingAddress = [
          mailingAddress.address.street,
          mailingAddress.address.city,
          mailingAddress.address.state,
          mailingAddress.address.zip,
        ].join('|');
        const serializedTerminalAddress = [
          terminalAddress.address.street,
          terminalAddress.address.city,
          terminalAddress.address.state,
          terminalAddress.address.zip,
        ].join('|');
        setIsSameAsMailingAddress(
          serializedMailingAddress === serializedTerminalAddress,
        );
      },
    },
  );

  return (
    <div className="flex flex-row items-start">
      <h6 className="font-bold basis-1/3">Terminal Address</h6>
      <div className="basis-4/6">
        <FormControl>
          <Checkbox
            checked={isSameAsMailingAddress}
            name="sameAsMailingAddress"
            onChange={(e) => {
              const address = data?.mailingAddress || {};

              if (e.target.checked) {
                setValue('terminalAddress', address);
              } else {
                setValue('terminalAddress', {
                  street: '',
                  city: '',
                  state: '',
                  zip: '',
                });
              }

              setIsSameAsMailingAddress((prev) => !prev);
            }}
          >
            Same as mailing address
          </Checkbox>
        </FormControl>

        <Show when={!isSameAsMailingAddress}>
          <div className="grid grid-cols-6 gap-4 mt-8">
            <Controller
              control={control}
              defaultValue=""
              name="terminalAddress.street"
              rules={{
                required: 'Please enter street name',
              }}
              render={({ field: { value, onChange } }) => {
                return (
                  <FormControl className="col-span-6">
                    <FormHelperText className="mt-0 text-primary-main">
                      Address
                    </FormHelperText>
                    <AddressSearch
                      value={value}
                      onValueChange={onChange}
                      onPlaceSelect={(placeDetails) => {
                        onChange(placeDetails?.street);
                        setValue('terminalAddress.city', placeDetails?.city);
                        setValue('terminalAddress.state', placeDetails?.state);
                        setValue('terminalAddress.zip', placeDetails?.zip);
                      }}
                    />

                    {!!(errors?.terminalAddress as any)?.street && (
                      <FormHelperText error>
                        {(errors?.terminalAddress as any)?.street?.message}
                      </FormHelperText>
                    )}
                  </FormControl>
                );
              }}
            />
            <FormControl className="col-span-2">
              <InputWithLabel
                noMargin
                label="City"
                placeholder=""
                formControlProps={{
                  fullWidth: true,
                }}
                error={!!(errors?.terminalAddress as any)?.city?.message}
                helperText={(errors?.terminalAddress as any)?.city?.message}
                {...register('terminalAddress.city', {
                  required: 'Please enter city',
                })}
              />
            </FormControl>
            <FormControl className="col-span-2" size="small">
              <FormHelperText className="mt-0 text-primary-main">
                State
              </FormHelperText>
              <Controller
                control={control}
                defaultValue=""
                name={'terminalAddress.state'}
                rules={{ required: 'Please select state' }}
                render={({ field: { onChange, value } }) => {
                  return (
                    <Select
                      size="small"
                      displayEmpty
                      variant="outlined"
                      value={value}
                      onChange={(e) => onChange(e.target.value)}
                      error={!!(errors?.terminalAddress as any)?.state?.message}
                    >
                      <MenuItem value="">
                        <Typography color="text.hint">State</Typography>
                      </MenuItem>
                      {constants.usStates.map((record) => (
                        <MenuItem value={record.name} key={record.code}>
                          {record.name}
                        </MenuItem>
                      ))}
                    </Select>
                  );
                }}
              />
              {!!(errors?.terminalAddress as any)?.state && (
                <FormHelperText error>
                  {(errors?.terminalAddress as any)?.state?.message}
                </FormHelperText>
              )}
            </FormControl>
            <FormControl className="col-span-2">
              <FormHelperText className="mt-0 text-primary-main">
                Zip
              </FormHelperText>
              <Controller
                control={control}
                defaultValue=""
                name="terminalAddress.zip"
                rules={{
                  required: 'Enter zip',
                  validate: {
                    isExtendedZip: (value) => {
                      return (
                        value.length <= 5 ||
                        'Please remove the zip code extension'
                      );
                    },
                    isValidZip: async (value) => {
                      try {
                        await mutateAsync(value);

                        return true;
                      } catch (ex) {
                        return 'Please enter a valid ZIP code';
                      }
                    },
                  },
                }}
                render={({ field: { value, onChange } }) => (
                  <InputZip
                    placeholder="eg. 12345"
                    value={value}
                    onChange={(e) => onChange(e.target.value)}
                    error={!!(errors?.terminalAddress as any)?.zip?.message}
                    helperText={(errors?.terminalAddress as any)?.zip?.message}
                  />
                )}
              />
            </FormControl>
          </div>
        </Show>
      </div>
    </div>
  );
};

export default TerminalAddress;
