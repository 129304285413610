import { CoverageGroup1 } from '@nirvana/api/endorsementapp';
import { Dialog, Show } from '@nirvana/ui-kit';
import NumberFormat from 'react-number-format';

type CoverageModalProps = {
  open: boolean;
  onClose: () => void;
  coverageGroups?: CoverageGroup1[];
};

const CoverageModal = ({
  open,
  onClose,
  coverageGroups,
}: CoverageModalProps) => {
  return (
    <Dialog
      open={open}
      onClose={onClose}
      PaperProps={{
        className: 'w-full max-w-5xl max-h-screen',
      }}
      title={<p className="text-sm font-bold text-text-primary">Coverages</p>}
    >
      <div className="-mx-6 -mt-6 border-b border-gray-300" />

      <div className="p-6 space-y-8 overflow-y-auto max-h-[70vh]">
        {coverageGroups?.map(({ parent, children }, index) => (
          <div key={parent.name} className="space-y-4">
            <span>
              <h3 className="text-base font-bold text-text-primary">
                {parent.name}
              </h3>
              <p className="text-xs text-text-hint">{parent.description}</p>
            </span>

            <Show when={children && children.length > 0}>
              <div>
                <table className="min-w-full bg-white table-fixed">
                  <thead>
                    <tr>
                      <th
                        scope="col"
                        className="w-1/3 px-4 py-3 text-xs font-medium text-left align-middle text-text-hint"
                      >
                        Coverage
                      </th>
                      <th
                        scope="col"
                        className="w-1/3 px-4 py-3 text-xs font-medium text-left align-middle text-text-hint"
                      >
                        Limit
                      </th>
                      <th
                        scope="col"
                        className="w-1/3 px-4 py-3 text-xs font-medium text-left align-middle text-text-hint"
                      >
                        Deductible
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {children.map((child) => (
                      <tr key={child.name}>
                        <td className="px-4 py-3 text-xs font-medium align-middle text-text-primary">
                          {child.name}
                        </td>
                        <td className="px-4 py-3 text-xs align-middle text-text-primary">
                          {child.limits?.length
                            ? child.limits.map((limit, idx) => (
                                <NumberFormat
                                  key={idx}
                                  value={limit}
                                  displayType="text"
                                  thousandSeparator
                                  prefix="$"
                                  className="block"
                                />
                              ))
                            : '--'}
                        </td>
                        <td className="px-4 py-3 text-xs align-middle text-text-primary">
                          <NumberFormat
                            value={child.deductible}
                            displayType="text"
                            thousandSeparator
                            prefix="$"
                          />
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </Show>

            {index < (coverageGroups?.length || 0) - 1 && (
              <hr className="mt-4 border-t text-text-disabled" />
            )}
          </div>
        ))}
      </div>
    </Dialog>
  );
};

export default CoverageModal;
