type StateLicenseRecord = {
  [key: string]: {
    rule: string;
    description: string[];
  };
};

const stateLicenseRegex: StateLicenseRecord = {
  AL: {
    rule: '^[0-9]{1,8}$',
    description: ['1-8 Numeric'],
  },
  AK: {
    rule: '^[0-9]{1,7}$',
    description: ['1-7 Numbers'],
  },
  AZ: {
    rule: '(^[a-zA-Z]{1}[0-9]{1,8}$)|(^[a-zA-Z]{2}[0-9]{2,5}$)|(^[0-9]{9}$)',
    description: [
      '1 Alpha + 1-8 Numeric',
      '2 Alpha + 2-5 Numeric',
      '9 Numeric',
    ],
  },
  AR: {
    rule: '^[0-9]{4,9}$',
    description: ['4-9 Numeric'],
  },
  CA: {
    rule: '^[a-zA-Z]{1}[0-9]{7}$',
    description: ['1 Alpha + 7 Numeric'],
  },
  CO: {
    rule: '(^[0-9]{9}$)|(^[a-zA-Z]{1}[0-9]{3,6}$)|(^[a-zA-Z]{2}[0-9]{2,5}$)',
    description: [
      '9 Numeric',
      '1 Alpha + 3-6 Numeric',
      '2 Alpha + 2-5 Numeric',
    ],
  },
  CT: {
    rule: '^[0-9]{9}$',
    description: ['9 Numeric'],
  },
  DE: {
    rule: '^[0-9]{1,7}$',
    description: ['1-7 Numeric'],
  },
  DC: {
    rule: '(^[0-9]{7}$)|(^[0-9]{9}$)',
    description: ['7 Numeric', '9 Numeric'],
  },
  FL: {
    rule: '^[a-zA-Z]{1}[0-9]{12}$',
    description: ['1 Alpha + 12 Numeric'],
  },
  GA: {
    rule: '^[0-9]{7,9}$',
    description: ['7-9 Numeric'],
  },
  GU: {
    rule: '^[a-zA-Z]{1}[0-9]{14}$',
    description: ['1 Alpha + 14 Numeric'],
  },
  HI: {
    rule: '(^[a-zA-Z]{1}[0-9]{8}$)|(^[0-9]{9}$)',
    description: ['1 Alpha + 8 Numeric', '9 Numeric'],
  },
  ID: {
    rule: '(^[a-zA-Z]{2}[0-9]{6}[a-zA-Z]{1}$)|(^[0-9]{9}$)',
    description: ['2 Alpha + 6 Numeric + 1 Alpha', '9 Numeric'],
  },
  IL: {
    rule: '^[a-zA-Z]{1}[0-9]{11,12}$',
    description: ['1 Alpha + 11-12 Numeric'],
  },
  IN: {
    rule: '(^[a-zA-Z]{1}[0-9]{9}$)|(^[0-9]{9,10}$)',
    description: ['1 Alpha + 9 Numeric', '9-10 Numeric'],
  },
  IA: {
    rule: '^([0-9]{9}$)|([0-9]{3}[a-zA-Z]{2}[0-9]{4})$',
    description: ['9 Numeric', '3 Numeric + 2 Alpha + 4 Numeric'],
  },
  KS: {
    rule: '(^([a-zA-Z]{1}[0-9]{1}){2}[a-zA-Z]{1}$)|(^[0-9]{9}$)|(^[a-zA-Z]{1}[0-9]{8}$)',
    description: [
      '1 Alpha + 1 Numeric + 1 Alpha + 1 Numeric + 1 Alpha',
      '1 Alpha + 8 Numeric',
      '9 Numeric',
    ],
  },
  KY: {
    rule: '(^[a-zA-Z]{1}[0-9]{8,9}$)|(^[0-9]{9}$)',
    description: ['1 Alpha + 8-9 Numeric', '9 Numeric'],
  },
  LA: {
    rule: '^[0-9]{1,9}$',
    description: ['1-9 Numeric'],
  },
  ME: {
    rule: '(^[0-9]{7,8}$)|(^[0-9]{7}[a-zA-Z]{1}$)',
    description: ['7-8 Numeric', '7 Numeric + 1 Alpha'],
  },
  MD: {
    rule: '^[a-zA-Z]{1}[0-9]{12}$|^MD[0-9]{11}$',
    description: ['1Alpha+12Numeric'],
  },
  MA: {
    rule: '(^[a-zA-Z]{1}[0-9]{8}$)|(^[a-zA-Z]{2}[0-9]{7}$)|(^[0-9]{9}$)',
    description: ['1 Alpha + 8 Numeric', '2 Alpha + 7 Numeric', '9 Numeric'],
  },
  MI: {
    rule: '(^[a-zA-Z]{1}[0-9]{10}$)|(^[a-zA-Z]{1}[0-9]{12}$)',
    description: ['1 Alpha + 10 Numeric', '1 Alpha + 12 Numeric'],
  },
  MN: {
    rule: '^[a-zA-Z]{1}[0-9]{12}$',
    description: ['1 Alpha + 12 Numeric'],
  },
  MS: {
    rule: '^[0-9]{9}$',
    description: ['9 Numeric'],
  },
  MO: {
    rule: '(^[a-zA-Z]{1}[0-9]{5,9}$)|(^[a-zA-Z]{1}[0-9]{6}[R]{1}$)|(^[0-9]{3}[a-zA-Z]{1}[0-9]{6}$)|(^[0-9]{8}[a-zA-Z]{2}$)|(^[0-9]{9}[a-zA-Z]{1}$)|(^[0-9]{9}$)',
    description: [
      '1 Alpha + 5-9 Numeric',
      "1 Alpha + 6 Numeric + 'R'",
      '8 Numeric + 2 Alpha',
      '9 Numeric + 1 Alpha',
      '9 Numeric',
      '3 Numeric + 1 Alpha + 6 Numeric',
    ],
  },
  MT: {
    rule: '(^[a-zA-Z]{1}[0-9]{8}$)|(^[0-9]{13}$)|(^[0-9]{9}$)|(^[0-9]{14}$)|(^[a-zA-Z]{3}[0-9]{10})',
    description: [
      '1 Alpha + 8 Numeric',
      '13 Numeric',
      '9 Numeric',
      '14 Numeric',
      '3 Alpha + 10 Numeric',
    ],
  },
  NE: {
    rule: '(^[0-9]{1,7}$)|(^[a-zA-Z]{1}[0-9]{6,8}$)',
    description: ['1-7 Numeric', '1Alpha+6-8Numeric'],
  },
  NV: {
    rule: '(^[0-9]{9,10}$)|(^[0-9]{12}$)|(^[X]{1}[0-9]{8}$)',
    description: ['9 Numeric', '10 Numeric', '12 Numeric', "'X' + 8 Numeric"],
  },
  NH: {
    rule: '(^[0-9]{2}[a-zA-Z]{3}[0-9]{5}$)|(^[a-zA-Z]{3}[0-9]{8}$)',
    description: ['2 Numeric + 3 Alpha + 5 Numeric'],
  },
  NJ: {
    rule: '^[a-zA-Z]{1}[0-9]{14}$',
    description: ['1 Alpha + 14 Numeric'],
  },
  NM: {
    rule: '^[0-9]{8,9}$',
    description: ['8 Numeric', '9 Numeric'],
  },
  NY: {
    rule: '(^[a-zA-Z]{1}[0-9]{7}$)|(^[a-zA-Z]{1}[0-9]{18}$)|(^[0-9]{8}$)|(^[0-9]{9}$)|(^[0-9]{16}$)|(^[a-zA-Z]{8}$)',
    description: [
      '1 Alpha + 7 Numeric',
      '1 Alpha + 18 Numeric',
      '8 Numeric',
      '9 Numeric',
      '16 Numeric',
      '8 Alpha',
    ],
  },
  NC: {
    rule: '^[0-9]{1,12}$',
    description: ['1-12 Numeric'],
  },
  ND: {
    rule: '(^[a-zA-Z]{3}[0-9]{6}$)|(^[0-9]{9}$)',
    description: ['3 Alpha + 6 Numeric', '9 Numeric'],
  },
  OH: {
    rule: '(^[a-zA-Z]{1}[0-9]{4,8}$)|(^[a-zA-Z]{2}[0-9]{3,7}$)|(^[0-9]{8}$)',
    description: [
      '1 Alpha + 4-8 Numeric',
      '2 Alpha + 3-7 Numeric',
      '8 Numeric',
    ],
  },
  OK: {
    rule: '(^[a-zA-Z]{1}[0-9]{9}$)|(^[0-9]{9}$)',
    description: ['1 Alpha + 9 Numeric', '9 Numeric'],
  },
  OR: {
    rule: '(^[0-9]{1,9}$)|(^[A-Za-z][0-9]{6,7}$)',
    description: ['1-9 Numeric', 'One Alpha + 6-7 Numberic'],
  },
  PA: {
    rule: '^[0-9]{8}$',
    description: ['8 Numeric'],
  },
  PR: {
    rule: '(^[0-9]{9}$)|(^[0-9]{5,7}$)',
    description: ['5-7 Numeric', '9 Numeric'],
  },
  RI: {
    rule: '^([0-9]{7,8}$)|(^[a-zA-Z]{1}[0-9]{6}$)',
    description: ['7-8 Numeric', '1 Alpha + 6 Numeric'],
  },
  SC: {
    rule: '^[0-9]{5,11}$',
    description: ['5-11 Numeric'],
  },
  SD: {
    rule: '(^[0-9]{6,10}$)|(^[0-9]{12}$)',
    description: ['6-10 Numeric', '12 Numeric'],
  },
  TN: {
    rule: '^[0-9]{7,9}$',
    description: ['7-9 Numeric'],
  },
  TX: {
    rule: '^[0-9]{6,8}$',
    description: ['6-8 Numeric'],
  },
  UT: {
    rule: '^[0-9]{4,10}$',
    description: ['4-10 Numeric'],
  },
  VT: {
    rule: '(^[0-9]{8}$)|(^[0-9]{7}[A]$)',
    description: ['8 Numeric', "7 Numeric + 'A'"],
  },
  VA: {
    rule: '(^[a-zA-Z]{1}[0-9]{8,11}$)|(^[0-9]{9}$)',
    description: [
      '1 Alpha + 8 Numeric',
      '1 Alpha + 9 Numeric',
      '1 Alpha + 10 Numeric',
      '1 Alpha + 11 Numeric',
      '9 Numeric',
    ],
  },
  WA: {
    rule: '^(?=.{12}$)[a-zA-Z]{1,7}[a-zA-Z0-9\\*]{4,11}$',
    description: [
      '1-7 Alpha + any combination of Alpha, Numeric, and * for a total of 12 characters',
    ],
  },
  WV: {
    rule: '(^[0-9]{7}$)|(^[a-zA-Z]{1,2}[0-9]{5,6}$)',
    description: ['7 Numeric', '1-2 Alpha + 5-6 Numeric'],
  },
  WI: {
    rule: '^[a-zA-Z]{1}[0-9]{13}$',
    description: ['1 Alpha + 13 Numeric'],
  },
  WY: {
    rule: '^[0-9]{9,10}$',
    description: ['9-10 Numeric'],
  },
};

export default stateLicenseRegex;
