import { TableV8 } from '@nirvana/ui-kit';
import { useQuery } from '@tanstack/react-query';
import { useMemo } from 'react';
import { useParams } from 'react-router-dom';
import { AddressChangeChangeTypeEnum } from '@nirvana/api/endorsementapp';

import { getMiscellaneousColumns } from '../../constants/miscellaneous';
import { fetchEndorsementRequestMiscellaneous } from '../../queries/endorsement';
import EditLayout, { EndorsementType } from './layout';

export default function Miscellaneous() {
  const { policyId = '', endorsementId = '' } = useParams();

  const { data } = useQuery(
    ['endorsement-request-miscellaneous', policyId, endorsementId],
    () => fetchEndorsementRequestMiscellaneous(policyId, endorsementId),
  );

  const formattedData = useMemo(() => {
    const formatted = [];

    if (data?.additionalInsureds) {
      formatted.push({
        field: 'Additional Insured',
        changeType: AddressChangeChangeTypeEnum.Unchanged,
        value: data.additionalInsureds
          ?.map((record) => record?.additionalInsured?.name)
          .join(', '),
      });
    }

    if (data?.mailingAddress) {
      formatted.push({
        field: 'Mailing Address',
        changeType: data.mailingAddress.changeType,
        value: [
          data.mailingAddress.address?.street,
          data.mailingAddress.address?.city,
          data.mailingAddress.address?.state,
          data.mailingAddress.address?.zip,
          data.mailingAddress.address?.county,
        ]
          .filter((record) => !!record)
          .join(', '),
      });
    }

    if (data?.terminalAddress) {
      formatted.push({
        field: 'Terminal Address',
        changeType: data.terminalAddress.changeType,
        value: [
          data.terminalAddress.address?.street,
          data.terminalAddress.address?.city,
          data.terminalAddress.address?.state,
          data.terminalAddress.address?.zip,
          data.terminalAddress.address?.county,
        ]
          .filter((record) => !!record)
          .join(', '),
      });
    }

    return formatted;
  }, [data]);

  return (
    <EditLayout
      title="Miscellaneous"
      endorsementType={EndorsementType.Miscellaneous}
    >
      <TableV8 columns={getMiscellaneousColumns()} data={formattedData} />
    </EditLayout>
  );
}
