import { Chip, IChipProps } from '@nirvana/ui-kit';
import { createColumnHelper } from '@tanstack/react-table';
import _ from 'lodash';
// import DownloadIcon from 'src/assets/icons/download.svg';
import {
  EndorsementRequestDetails,
  EndorsementRequestState,
} from '@nirvana/api/endorsementapp';
import { format, parseISO } from 'date-fns';

import NumberFormat from 'react-number-format';
import SuccessIcon from 'src/assets/icons/check-circle-sm.svg';
import ErrorIcon from 'src/assets/icons/cross-circle-sm.svg';
import { formatEndorsementNumber } from '../utils';

function getEndorsementStatusChipProps(status: string): IChipProps {
  switch (status) {
    case EndorsementRequestState.EndorsementRequestStateCreated:
      return { label: 'In Progress', color: 'grey' };

    case EndorsementRequestState.EndorsementRequestStateUnderUwReview:
      return { label: 'Submitted', color: 'info' };

    case EndorsementRequestState.EndorsementRequestStateApproved:
      return { label: 'Quoted', color: 'gold' };

    case EndorsementRequestState.EndorsementRequestStateBound:
      return {
        label: 'Processed',
        color: 'success',
        icon: <img src={SuccessIcon} />,
      };

    case EndorsementRequestState.EndorsementRequestStateDeclined:
      return {
        label: 'Declined',
        color: 'error',
        icon: <img src={ErrorIcon} />,
      };

    case EndorsementRequestState.EndorsementRequestStateClosed:
      return { label: 'Closed', color: 'grey' };

    case EndorsementRequestState.EndorsementRequestStateInvalid:
      return { label: 'Invalid', color: 'error' };

    case EndorsementRequestState.EndorsementRequestStateOutOfSync:
      return { label: 'Stale', color: 'error' };

    default:
      return { label: status, color: 'warning' };
  }
}

const columnHelper = createColumnHelper<EndorsementRequestDetails>();

export const endorsementColumns = [
  columnHelper.accessor('endorsementNumber', {
    size: 500,
    header: 'ID & Changes',
    cell: ({ getValue, row }) => {
      // Format the endorsement number to include leading zeros (e.g., 001.1 instead of 1.1)
      return (
        <div className="text-text-primary">
          <p className="mb-0.5 font-bold">
            #{formatEndorsementNumber(getValue())}:
          </p>
          <p className="text-xs">
            {_.flatMap(
              row.original.endorsementDescription.map(
                (record) => record?.descriptions ?? [],
              ),
            )?.join(', ') || '-'}
          </p>
        </div>
      );
    },
  }),
  columnHelper.accessor('endorsementFee', {
    header: 'Premium',
    cell: ({ getValue }) => {
      const value = getValue();
      return value != null ? (
        <NumberFormat
          value={value}
          displayType="text"
          thousandSeparator
          prefix="$"
        />
      ) : (
        '-'
      );
    },
  }),
  columnHelper.accessor('effectiveDate', {
    header: 'Effective Date',
    cell: ({ getValue }) => {
      const value = getValue();
      return (
        <p className="text-text-primary">
          {value ? format(parseISO(value), 'MM/dd/yyyy') : '-'}
        </p>
      );
    },
  }),
  columnHelper.accessor('state', {
    header: 'Status',
    cell: ({ getValue }) => (
      <Chip {...getEndorsementStatusChipProps(getValue())} />
    ),
  }),
  // columnHelper.display({
  //   id: 'download',
  //   cell: () => (
  //     <IconButton size="small">
  //       <img src={DownloadIcon} alt="Download File" width={16} />
  //     </IconButton>
  //   ),
  // }),
];
