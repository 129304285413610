import { Divider } from '@material-ui/core';
import { useEffect } from 'react';
// import AdditionalInsured from './additional-insured';
import { useQuery } from '@tanstack/react-query';
import { useFormContext } from 'react-hook-form-v7';
import { useParams } from 'react-router-dom';
import { fetchMiscEndorsement } from 'src/features/policy/queries/endorsement';
import MailingAddress from './mailing-address';
import TerminalAddress from './terminal-address';

const MiscellaneousUpdate = () => {
  const { reset } = useFormContext();
  const { policyId = '', endorsementId = '' } = useParams();

  useEffect(() => {
    // Scroll to top on page load
    window.scrollTo(0, 0);
  }, []);

  useQuery(
    ['endorsement-misc'],
    () =>
      fetchMiscEndorsement({
        policyId,
        endorsementId,
      }),
    {
      onSuccess: (data) => {
        const { mailingAddress, terminalAddress } = data;
        reset({
          mailingAddress: mailingAddress.address || {},
          terminalAddress: terminalAddress.address || {},
        });
      },
    },
  );

  return (
    <div className="space-y-8">
      <MailingAddress />

      <Divider />

      <TerminalAddress />

      {/* <Divider />

      <AdditionalInsured /> */}
    </div>
  );
};

export default MiscellaneousUpdate;
