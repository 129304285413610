import {
  Box,
  Container,
  Divider,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  Typography,
} from '@material-ui/core';
import { storage, TableV8 as Table, TableTabs as Tabs } from '@nirvana/ui-kit';
import { useMemo, useState } from 'react';
import { useQuery } from '@tanstack/react-query';

import GlobalFilter from 'src/components/globalFilter';
import PageHeader from 'src/components/pageHeader';

import clsx from 'clsx';
import { useNavigate } from 'react-router-dom';
import {
  GetInsuranceBundleListRecordStatusEnum,
  ProgramType,
} from '@nirvana/api/endorsementapp';
import { agentDetailStatus } from 'src/features/auth/queries';
import { ONBOARDING_AGENCY_INFO_FLAG } from 'src/constants';
import { getColumns } from '../constants/columns';
import { fetchPoliciesList } from '../queries/policy';

/**
 * Policy list page that list out all policies for the logged in user.
 * Corresponds to Policy List Page (https://www.figma.com/design/Hg1IDFWSybQpYf7Okg8S1Z/Endorsements?node-id=2474-35846&t=s8dlAI3S2GX8Jej8-1)
 * @component
 */
const PoliciesList = () => {
  const navigate = useNavigate();
  const [selectedTab, setSelectedTab] = useState<
    GetInsuranceBundleListRecordStatusEnum | ''
  >('');
  const [searchText, setSearchText] = useState<string>('');

  const { data = [], isLoading } = useQuery(['policies-list'], () =>
    fetchPoliciesList(ProgramType.ProgramTypeNonFleetAdmitted),
  );

  const filteredData = useMemo(() => {
    // Filter by selectedTab and searchText
    let newData = [...(data ?? [])];
    if (selectedTab) {
      newData = newData.filter((item) => {
        return item.status === selectedTab;
      });
    }

    if (searchText) {
      newData = newData.filter((item) => {
        return (
          item.primaryInsured.name
            ?.toLowerCase()
            .includes(searchText.toLowerCase()) ||
          item.policy.policyNumber
            ?.toLowerCase()
            .includes(searchText.toLowerCase()) ||
          (item.primaryInsured.DOTNumber + '').includes(searchText)
        );
      });
    }

    return newData;
  }, [data, searchText, selectedTab]);

  useQuery(['agent-details'], () => agentDetailStatus(), {
    onSuccess: (data) => {
      const agencyInfoFlag = storage.get(ONBOARDING_AGENCY_INFO_FLAG);
      // If the user's details are incomplete and they have not completed the onboarding process,
      // redirect them to the onboarding page to fill in the required information.
      if (data && !data.isDetailsComplete && !agencyInfoFlag) {
        navigate('/onboarding/agency/info', {
          state: { backgroundLocation: { pathname: '/policies/list' } },
        });
      }
    },
  });

  const columns = useMemo(() => getColumns(), []);
  const policyCountByStatus = useMemo(() => {
    const countByStatus = data.reduce((acc, item) => {
      acc[item.status] = (acc[item.status] ?? 0) + 1;
      return acc;
    }, {} as Record<GetInsuranceBundleListRecordStatusEnum, number>);

    return countByStatus;
  }, [data]);

  const tabs = [
    {
      label: 'All',
      value: '',
      count: data?.length,
    },
    {
      label: 'Active',
      value: GetInsuranceBundleListRecordStatusEnum.Active,
      count: policyCountByStatus[GetInsuranceBundleListRecordStatusEnum.Active],
    },
    {
      label: 'Renewed',
      value: GetInsuranceBundleListRecordStatusEnum.Renewed,
      count:
        policyCountByStatus[GetInsuranceBundleListRecordStatusEnum.Renewed],
    },
    {
      label: 'Canceled',
      value: GetInsuranceBundleListRecordStatusEnum.Cancelled,
      count:
        policyCountByStatus[GetInsuranceBundleListRecordStatusEnum.Cancelled],
    },
    {
      label: 'Expired',
      value: GetInsuranceBundleListRecordStatusEnum.Expired,
      count:
        policyCountByStatus[GetInsuranceBundleListRecordStatusEnum.Expired],
    },
    // {
    //   label: 'Pending Cancellation',
    //   value: GetInsuranceBundleListRecordStatusEnum.PendingCancellation,
    //   count:
    //     policyCountByStatus[GetInsuranceBundleListRecordStatusEnum.PendingCancellation],
    // },
    // {
    //   label: 'Up for Renewal',
    //   value: GetInsuranceBundleListRecordStatusEnum.UpForRenewal,
    //   count: policyCountByStatus[GetInsuranceBundleListRecordStatusEnum.UpForRenewal],
    // },
  ];

  return (
    <Box padding={4}>
      <Container maxWidth="lg">
        <Grid container direction="column" spacing={2}>
          <Grid item>
            <PageHeader
              title={
                <>
                  <Typography
                    variant="h4"
                    color="text.secondary"
                    fontWeight="fontWeightBold"
                    marginBottom={3}
                  >
                    Policies
                  </Typography>
                  <div className="flex gap-3">
                    <GlobalFilter value={searchText} onChange={setSearchText} />

                    <div className="flex py-1">
                      <Divider orientation="vertical" flexItem />
                    </div>
                    <FormControl variant="outlined">
                      <InputLabel>Program</InputLabel>
                      <Select
                        disabled
                        label="Program"
                        className="w-[220px]"
                        variant="outlined"
                        value={'NON_FLEET'}
                        onChange={() => null}
                        renderValue={(value) => {
                          switch (value) {
                            case 'FLEET':
                              return 'Fleet';
                            case 'NON_FLEET':
                              return 'Non-fleet';
                            default:
                              return '';
                          }
                        }}
                      >
                        <MenuItem value="FLEET">Fleet (10+ PUs)</MenuItem>
                        <MenuItem value="NON_FLEET">
                          Non-fleet (1-9 PUs)
                        </MenuItem>
                      </Select>
                    </FormControl>
                  </div>
                </>
              }
              actions={null}
            />
          </Grid>
          <Grid item>
            <Paper variant="outlined">
              <Box px={3} py={1}>
                <Grid container direction="column" spacing={5}>
                  <Grid item>
                    <Tabs
                      value={selectedTab}
                      onChange={(value) => {
                        setSelectedTab(value);
                      }}
                      tabs={tabs}
                    />
                  </Grid>
                  <Grid item>
                    <div role="table">
                      <Table
                        isLoading={isLoading}
                        columns={columns}
                        data={filteredData}
                        onCell={() => ({ className: '!p-[14px]' })}
                        onRow={(row, index) => {
                          const disabledStates = ['inactive'];

                          const isDisabled: boolean = disabledStates.includes(
                            row.status ?? '',
                          );

                          return {
                            role: 'row',
                            className: clsx(
                              'hover:bg-primary-extraLight/[0.65]',
                              {
                                'bg-primary-extraLight/[0.45]': index % 2,
                                'cursor-not-allowed': isDisabled,
                                'cursor-pointer': !isDisabled,
                              },
                            ),
                            onClick: () => {
                              if (isDisabled) {
                                return;
                              }

                              navigate(`/policies/${row.bundleID}`);
                            },
                          };
                        }}
                      />
                    </div>
                  </Grid>
                </Grid>
              </Box>
            </Paper>
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
};

export default PoliciesList;
