import type { LoginRequest, LoginResponse } from '@nirvana/api/auth';
import { AuthApi, Configuration } from '@nirvana/api/auth';
import { AxiosResponse } from 'axios';

import { storage } from 'src/helpers';
import {
  AUTH_TOKEN_STORAGE_KEY,
  ONBOARDING_AGENCY_INFO_FLAG,
} from 'src/constants';

import { preloadApp } from 'src/features/init/actions';
import { setLoading, setLoginFail, setLogOut } from '../slices';

const configOptions = new Configuration();
configOptions.isJsonMime = () => false;
const apiService = new AuthApi(configOptions);

export interface LoginResult {
  access_token: string;
  token_type: string;
}

export const login = (credentials: LoginRequest) => async (dispatch: any) => {
  try {
    dispatch(setLoading(true));

    const response: AxiosResponse<LoginResponse> =
      await apiService.authLoginPost(credentials);

    const { data } = response;

    // Store auth token in local storage
    storage.set(AUTH_TOKEN_STORAGE_KEY, data.sessionId);

    dispatch(preloadApp());

    return data.sessionId;
  } catch (ex: any) {
    dispatch(setLoginFail({ message: ex.message }));
    return Promise.reject(ex);
  } finally {
    dispatch(setLoading(false));
  }
};

export const signUp = (sessionID: string) => async (dispatch: any) => {
  try {
    dispatch(setLoading(true));

    // Store auth token in local storage
    storage.set(AUTH_TOKEN_STORAGE_KEY, sessionID);

    dispatch(preloadApp());
  } catch (e) {
    return Promise.reject(e);
  } finally {
    dispatch(setLoading(false));
  }
};

export const logout = () => async (dispatch: any) => {
  // remove auth token and agency info flag from local storage
  storage.remove(AUTH_TOKEN_STORAGE_KEY);
  storage.remove(ONBOARDING_AGENCY_INFO_FLAG);

  dispatch(setLogOut());
};
