import { Avatar } from '@material-ui/core';
import { GetInsuranceBundleResponse } from '@nirvana/api/endorsementapp';

export default function BusinessOwnerDetail({
  data,
}: {
  data?: GetInsuranceBundleResponse;
}) {
  const formatAddress = (address?: {
    street?: string;
    city?: string;
    state?: string;
    zip?: string;
  }) =>
    [address?.street, address?.city, address?.state, address?.zip]
      .filter(Boolean)
      .join(', ') || 'N/A';

  return (
    <section className="flex-1">
      <div className="flex items-start gap-2 mb-5">
        <Avatar className="bg-primary-extraLight">
          <p className="text-sm font-bold text-black">
            {data?.owner?.firstName?.[0]}
          </p>
        </Avatar>

        <div className="flex flex-col">
          <p className="text-base font-bold text-secondary">
            {[data?.owner?.firstName, data?.owner?.lastName]
              .filter(Boolean)
              .join(' ')}
          </p>
          <p className="text-sm">{data?.owner?.email}</p>
          <p className="text-sm">{data?.owner?.phoneNumber}</p>
        </div>

        <div className="mx-20 space-y-4">
          <div className="flex flex-col space-y-1">
            <p className="text-xs text-text-hint">Mailing Address</p>
            <p className="text-sm font-medium text-primary">
              {formatAddress(data?.mailingAddress)}
            </p>
          </div>
          <div className="flex flex-col space-y-1">
            <p className="text-xs text-text-hint">Terminal Address</p>
            <p className="text-sm font-medium text-primary">
              {formatAddress(data?.terminalAddress)}
            </p>
          </div>
          <div className="flex flex-col space-y-1">
            <p className="text-xs text-text-hint">Producer Contact</p>
            <p className="text-sm font-medium text-primary">
              {[data?.producer?.firstName, data?.producer?.lastName]
                .filter(Boolean)
                .join(' ') || 'N/A'}
              {data?.producer?.email ? `, ${data.producer.email}` : ''}
            </p>
          </div>
        </div>
      </div>
    </section>
  );
}
