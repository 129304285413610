import * as React from 'react';
import { useSelector } from 'react-redux';
import { Navigate } from 'react-router-dom';
import { initSelector } from 'src/features/init/slices';
import { authSelector } from '../../slices';

/**
 * HOC to handle redirection logic for unauthenticated pages.
 *
 * @param {Object} props.children - ReactNode
 *
 * @returns
 * If user is already logged in, Redirect to the appropriate base path based on role,
 * otherwise return the children.
 */
const RedirectHoc: React.FC = ({ children }) => {
  const { isLoggedIn } = useSelector(authSelector);
  const { user } = useSelector(initSelector);

  // Determine if the user has the "AgencyServiceMemberRole"
  const isAgencyServiceMemberRole = user?.roles?.agencyRoles?.some(
    (role) => role.role === 'AgencyServiceMemberRole',
  );

  if (isLoggedIn && user && user.roles) {
    // Redirect based on role
    const redirectPath = isAgencyServiceMemberRole
      ? '/policies/list'
      : '/applications/list';
    return <Navigate to={redirectPath} replace />;
  }

  return <>{children}</>;
};

export default RedirectHoc;
