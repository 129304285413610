import { AddressChangeChangeTypeEnum } from '@nirvana/api/endorsementapp';
import { Chip, Show } from '@nirvana/ui-kit';
import { createColumnHelper } from '@tanstack/react-table';
import { getChangeTypeMeta } from '../utils';

export type MiscellaneousData = {
  field: string;
  changeType: AddressChangeChangeTypeEnum;
  value: string;
};

const columnHelper = createColumnHelper<MiscellaneousData>();

export const getMiscellaneousColumns = () => [
  columnHelper.accessor('field', {
    size: 200,
    header: 'Type',
    cell: ({ getValue, row }) => (
      <p className="capitalize">
        <span className="font-bold">{getValue()}</span>
        &nbsp;
        <Show
          when={
            row.original.changeType &&
            row.original.changeType !== AddressChangeChangeTypeEnum.Unchanged
          }
        >
          <Chip
            label={row.original.changeType}
            color={getChangeTypeMeta(row.original.changeType)?.color}
          />
        </Show>
      </p>
    ),
  }),
  columnHelper.accessor('value', {
    header: 'Description',
    cell: ({ getValue }) => <div>{getValue()}</div>,
  }),
];
