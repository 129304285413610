import { CSSObject } from '@emotion/react';
import styled from '@emotion/styled';
import {
  alpha,
  Box,
  LinearProgress,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Drawer as MuiDrawer,
  Toolbar,
  Tooltip,
} from '@material-ui/core';
import { Chip, ITheme } from '@nirvana/ui-kit';
import { useQuery } from '@tanstack/react-query';
import { useSelector } from 'react-redux';
import { Link, useMatch, useResolvedPath } from 'react-router-dom';
import { initSelector } from 'src/features/init/slices';
import { Feature, useFeatureFlag } from 'src/helpers/featureFlags';
import { getLinks, ListItemProps } from './constants/links';
import { fetchTrainingStatus } from './queries';

const drawerWidth = 265;
const collpaseDrawerWidth = 44;

const openedMixin = (theme: ITheme): CSSObject => ({
  width: drawerWidth,
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
});

const closedMixin = (): CSSObject => ({
  // transition: theme.transitions.create('width', {
  //   easing: theme.transitions.easing.sharp,
  //   duration: theme.transitions.duration.short,
  // }),
  width: collpaseDrawerWidth,
});

const Drawer: any = styled(MuiDrawer, {
  shouldForwardProp: (prop: any) => prop !== 'open',
})(({ theme, open }: { theme: ITheme; open: boolean }) => ({
  width: drawerWidth,
  flexShrink: 0,
  zIndex: 8,
  ...(open ? openedMixin(theme) : closedMixin()),
  '& .MuiDrawer-paper': open ? openedMixin(theme) : closedMixin(),
}));

const BorderLinearProgress: any = styled(LinearProgress)(
  ({ theme }: { theme: ITheme }) => ({
    ...theme.shape,
    height: theme.spacing(1),
    width: theme.typography.pxToRem(60),
    marginTop: theme.typography.pxToRem(4),
    '&.MuiLinearProgress-colorPrimary': {
      backgroundColor: alpha(theme.palette.gold.main, 0.34),
    },
    '& .MuiLinearProgress-bar': {
      ...theme.shape,
      backgroundColor: theme.palette.gold.main,
    },
  }),
);

type TrainingStatus = 'new' | 'progress' | 'completed';

interface SidebarLinkProps extends ListItemProps {
  open: boolean;
  trainingStatus?: TrainingStatus;
  trainingProgress?: number;
}

const SidebarLink = (item: SidebarLinkProps) => {
  const resolved = useResolvedPath(item.path);
  const isActive = useMatch({ path: resolved.pathname, end: false });

  let listItemProps;
  if (item.external) {
    listItemProps = {
      component: 'a',
      target: '_blank',
      href: item.path,
    };
  } else {
    listItemProps = {
      component: Link,
      to: item.path,
    };
  }

  return item.open ? (
    <ListItem
      button
      {...listItemProps}
      sx={{
        backgroundColor: isActive ? 'primary.extraLight' : 'transparent',
        borderLeft: isActive ? '4px solid #3350A1' : '4px solid transparent',
        paddingTop: '10px',
        paddingBottom: '10px',
      }}
    >
      <ListItemIcon sx={{ minWidth: 18, marginRight: 1 }}>
        <img
          src={isActive ? item.activeIcon : item.icon}
          alt={item.label}
          height={16}
        />
      </ListItemIcon>
      <ListItemText
        primary={item.label}
        primaryTypographyProps={{
          variant: 'body2',
          color: isActive ? 'text.primary' : 'text.secondary',
          fontWeight: isActive ? 'fontWeightSemiBold' : 'fontWeightRegular',
          fontSize: 16,
        }}
      />
      {item.trainingStatus === 'new' ? (
        <Chip color="gold-dark" label="NEW" size="small" layout="rounded" />
      ) : item.trainingStatus === 'progress' && item.trainingProgress ? (
        <BorderLinearProgress
          variant="determinate"
          value={item.trainingProgress}
        />
      ) : null}
    </ListItem>
  ) : (
    <Tooltip title={item.label} placement="right">
      <ListItem
        button
        {...listItemProps}
        sx={{
          paddingLeft: 1,
          paddingRight: 1,
          justifyContent: 'center',
          height: 44,
        }}
      >
        <ListItemIcon sx={{ minWidth: 0 }}>
          <img
            src={isActive ? item.activeIcon : item.icon}
            alt={item.label}
            height={18}
          />
        </ListItemIcon>
      </ListItem>
    </Tooltip>
  );
};

const Sidebar = ({ collapse = false }: { collapse?: boolean }) => {
  const getFeatureValue = useFeatureFlag();
  const init = useSelector(initSelector);
  const { user } = init;
  const { data } = useQuery(
    ['acacemy-user-certifications', user?.id],
    () => fetchTrainingStatus(user?.id ?? ''),
    {
      enabled: !!user?.id,
    },
  );

  // Determine if the user has the "AgencyServiceMemberRole"
  const isUserServiceRole = user?.roles?.agencyRoles?.some(
    (role) => role.role === 'AgencyServiceMemberRole',
  );

  const firstIncomplete = data?.certifications?.find(
    (certification) => !certification.completed,
  );

  // If first certification has a progressPercentage and "completed" is false, it is in progress
  // Otherwise, check "completed" status of each certification, if any certification
  // is not completed, then trainingStatus is new

  let trainingStatus: TrainingStatus = 'completed';
  if (firstIncomplete) {
    if (
      firstIncomplete.isStarted &&
      firstIncomplete.progressPercentage > 0 &&
      firstIncomplete.progressPercentage < 100
    ) {
      // If training is started but not completed, then it is in progress
      trainingStatus = 'progress';
    } else if (
      !firstIncomplete.isStarted ||
      (firstIncomplete.isStarted && firstIncomplete.progressPercentage === 0)
    ) {
      // If training is not started, then it is new
      trainingStatus = 'new';
    }
  }

  const trainingProgress = data?.certifications?.[0]?.progressPercentage ?? 0;

  const Links = getLinks({
    hasHelp: getFeatureValue(Feature.FEATURE_HELP, false),
    hasPolicies: getFeatureValue(Feature.FEATURE_POLICIES_LIST, false),
    hasSafety: getFeatureValue(Feature.FEATURE_SAFETY_TAB, false),
    hasWorkramp: getFeatureValue(Feature.FEATURE_WORKRAMP_TAB, false),
    isUserServiceRole,
  });

  return (
    <Drawer variant="permanent" open={!collapse}>
      <Toolbar variant="dense" />
      <Box py={5} position="relative" flexGrow={1}>
        <List disablePadding>
          {Links.map((record: ListItemProps) => {
            const { key, ...rest } = record;

            if (key === 'academy') {
              return (
                <SidebarLink
                  key={key}
                  {...rest}
                  open={!collapse}
                  trainingStatus={trainingStatus}
                  trainingProgress={trainingProgress}
                />
              );
            }

            return <SidebarLink key={key} {...rest} open={!collapse} />;
          })}
        </List>
      </Box>
    </Drawer>
  );
};

export default Sidebar;
