import { IconButton } from '@material-ui/core';
import {
  Vehicle,
  VehicleChangeChangeTypeEnum,
  VehicleClass,
  VehicleType,
} from '@nirvana/api/endorsementapp';
import { Chip, formatNumber, Show } from '@nirvana/ui-kit';
import {
  AccessorColumnDef,
  ColumnDef,
  createColumnHelper,
} from '@tanstack/react-table';
import clsx from 'clsx';
import PencilIcon from 'src/assets/icons/pencil.svg';
import TrashIcon from 'src/assets/icons/trash.svg';
import Button from 'src/components/button';
import { getChangeTypeMeta } from '../utils';

export interface VehicleData extends Vehicle {
  changeType?: VehicleChangeChangeTypeEnum;
  oldValue?: Vehicle;
}

const columnHelper = createColumnHelper<VehicleData>();

type GetVehicleColumnParams = {
  onRemove?: (row: VehicleData) => void;
  onEdit?: (row: VehicleData) => void;
  onUndo?: (row: VehicleData) => void;
};

export const getVehicleColumns = ({
  onRemove,
  onEdit,
  onUndo,
}: GetVehicleColumnParams) => {
  const columns: Array<
    ColumnDef<VehicleData> | AccessorColumnDef<VehicleData, any>
  > = [
    columnHelper.accessor('vin', {
      header: 'Description',
      size: 200,
      enableSorting: true,
      cell: ({ getValue, row }) => {
        const { year, make, model } = row.original;

        return (
          <div>
            <p className="mb-0.5 capitalize">
              <span
                className={clsx('font-bold', {
                  'line-through text-text-hint':
                    row.original.changeType ===
                    VehicleChangeChangeTypeEnum.Deleted,
                })}
              >
                {getValue()}
              </span>
              <Show
                when={
                  row.original.changeType &&
                  row.original.changeType !==
                    VehicleChangeChangeTypeEnum.Unchanged
                }
              >
                &nbsp;
                <Chip
                  label={row.original.changeType}
                  color={getChangeTypeMeta(row.original.changeType)?.color}
                />
              </Show>
            </p>
            <p
              className={clsx('text-xs', {
                'line-through text-text-hint':
                  row.original.changeType ===
                  VehicleChangeChangeTypeEnum.Deleted,
              })}
            >
              {year} {make} {model}
            </p>
          </div>
        );
      },
    }),
    columnHelper.accessor('lossPayee', {
      header: 'Loss Payee',
      cell: ({ getValue }) => {
        const value = getValue();
        const { name, address } = value || {};

        return (
          <div>
            <p className="mb-0.5 capitalize">{name}</p>
            <p className="text-xs">
              {[address?.street, address?.city, address?.state, address?.zip]
                .filter((record) => !!record)
                .join(' ')}
            </p>
          </div>
        );
      },
    }),
    columnHelper.accessor('statedValue', {
      header: 'Stated Value',
      enableSorting: true,
      cell: ({ getValue }) => {
        const value = getValue();

        if (value) {
          return `$${formatNumber(getValue())}`;
        } else {
          return '-';
        }
      },
    }),
  ];

  if (onRemove && onEdit && onUndo) {
    columns.push(
      columnHelper.display({
        id: 'actions',
        cell: (props) => (
          <div className="space-x-6 text-right">
            <Show
              when={
                props.row.original.changeType !==
                VehicleChangeChangeTypeEnum.Deleted
              }
              fallback={
                <Button
                  variant="text"
                  size="small"
                  type="button"
                  onClick={() => onUndo(props.row.original)}
                >
                  Undo
                </Button>
              }
            >
              <IconButton
                size="small"
                onClick={() => {
                  onEdit(props.row.original);
                }}
              >
                <img src={PencilIcon} />
              </IconButton>
              <IconButton
                size="small"
                onClick={() => {
                  onRemove(props.row.original);
                }}
              >
                <img src={TrashIcon} />
              </IconButton>
            </Show>
          </div>
        ),
      }),
    );
  }

  return columns;
};

type VehicleConstants = {
  vehicleClassByType: Record<
    VehicleType,
    { label: string; value: VehicleClass }[]
  >;
  vehicleTypes: { label: string; value: VehicleType }[];
};

export const vehicleConstants: VehicleConstants = {
  vehicleClassByType: {
    [VehicleType.Pickup]: [
      { label: 'Pickup Truck', value: VehicleClass.PickupTruck },
      { label: 'Other', value: VehicleClass.Other },
    ],
    [VehicleType.Tractor]: [
      { label: 'Truck Tractor', value: VehicleClass.TruckTractor },
      { label: 'Other', value: VehicleClass.Other },
    ],
    [VehicleType.Trailer]: [
      { label: 'Auto Hauler', value: VehicleClass.AutoHauler },
      { label: 'Bulk Commodity', value: VehicleClass.BulkCommodity },
      { label: 'Dry Freight', value: VehicleClass.DryFreight },
      { label: 'Dump', value: VehicleClass.Dump },
      { label: 'Flatbed', value: VehicleClass.Flatbed },
      { label: 'Livestock', value: VehicleClass.Livestock },
      { label: 'Logging', value: VehicleClass.Logging },
      { label: 'Lowboy', value: VehicleClass.Lowboy },
      { label: 'Pole', value: VehicleClass.Pole },
      { label: 'Rag Top', value: VehicleClass.RagTop },
      { label: 'Reefer', value: VehicleClass.Reefer },
      { label: 'Tank', value: VehicleClass.Tank },
      { label: 'Tilt', value: VehicleClass.Tilt },
      { label: 'Utility', value: VehicleClass.Utility },
      { label: 'Other', value: VehicleClass.Other },
    ],
    [VehicleType.Truck]: [
      { label: 'Agricultural', value: VehicleClass.Agricultural },
      { label: 'Dump', value: VehicleClass.Dump },
      { label: 'Flatbed', value: VehicleClass.Flatbed },
      { label: 'Garbage', value: VehicleClass.Garbage },
      { label: 'Reefer', value: VehicleClass.Reefer },
      { label: 'Stake', value: VehicleClass.Stake },
      { label: 'Straight', value: VehicleClass.Straight },
      { label: 'Tank', value: VehicleClass.Tank },
      { label: 'Auto Hauler', value: VehicleClass.AutoHauler },
      { label: 'Other', value: VehicleClass.Other },
    ],
    [VehicleType.SemiTrailer]: [{ label: 'Other', value: VehicleClass.Other }],
    [VehicleType.SpareSemiTrailer]: [
      { label: 'Other', value: VehicleClass.Other },
    ],
    [VehicleType.NonOwnedSemiTrailer]: [
      { label: 'Other', value: VehicleClass.Other },
    ],
  },
  vehicleTypes: [
    { label: 'Truck', value: VehicleType.Truck },
    { label: 'Tractor', value: VehicleType.Tractor },
    { label: 'Trailer', value: VehicleType.Trailer },
    { label: 'Pickup', value: VehicleType.Pickup },
    // { label: 'Semi Trailer', value: VehicleType.SemiTrailer },
    // { label: 'Spare Semi Trailer', value: VehicleType.SpareSemiTrailer },
    // { label: 'Non-Owned Semi Trailer', value: VehicleType.NonOwnedSemiTrailer },
  ],
};
