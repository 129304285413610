import {
  AppBar,
  Box,
  Button,
  CircularProgress,
  Container,
  FormHelperText,
  Grid,
  makeStyles,
  Toolbar,
  Typography,
} from '@material-ui/core';
import { ApplicationDetail, ApplicationState } from '@nirvana/api/quoting';
import {
  ApplicationHeader,
  HorizontalStepper,
  ITheme,
  Show,
} from '@nirvana/ui-kit';
import _ from 'lodash';

import React, { useEffect, useMemo } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useSelector } from 'react-redux';
import { useBeforeUnload, useNavigate, useParams } from 'react-router-dom';
import {
  fetchApplicationById,
  removeActiveApplication,
} from 'src/features/application/actions';
import { useDispatch } from 'src/redux';
import { Feature, useFeatureFlag } from 'src/helpers/featureFlags';

import ArrowForwardIosRoundedIcon from '@material-ui/icons/ArrowForwardIosRounded';

import { useMutation } from '@tanstack/react-query';
import { useSnackbar } from 'notistack';
import { useIntercom } from 'react-use-intercom';
import {
  applicationSelector,
  fetchQuoteData,
  resetActiveApplication,
} from '../../../slices';
import Complete from '../../create/complete';
import useSectionLoadingReducer from '../loadingReducer';
import {
  submitRenewalApplication,
  updateFiles,
  updateSectionCompletionMap,
} from '../queries';
import { isStateNotProgress } from '../utils';
import { SECTION_NAMES } from '../constant';
import ApplicationRenewStep1 from './renewStep1';
import ApplicationRenewStep2 from './renewStep2';

const useStyles = makeStyles((theme: ITheme) => ({
  renewalContainer: {
    marginTop: theme.spacing(3),
  },
  footerToolbar: {
    backgroundColor: theme.palette.common.white,
    boxShadow: '0px -2px 4px rgba(128, 145, 196, 0.13)',
  },
  formHelperText: {
    marginBottom: theme.spacing(2),
    fontSize: theme.typography.pxToRem(14),
  },
}));

type RouteParams = {
  applicationId?: string;
};

const ApplicationRenewCreate = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { update: intercomUpdate } = useIntercom();
  const getFeatureValue = useFeatureFlag();
  const isApplicationPersistenceEnabled = getFeatureValue(
    Feature.APPLICATION_PERSISTANCE,
    false,
  );

  const params: RouteParams = useParams();
  const application = useSelector(applicationSelector);
  const classes = useStyles();
  const [activeStep, setActiveStep] = React.useState<number>(0);

  const { state, setLoading, reset: resetLoading } = useSectionLoadingReducer();

  const isRenewalsFlowEnabled = true;

  const { activeApplication: details } = application;
  const { applicationId = '' } = params;
  const { enqueueSnackbar } = useSnackbar();

  React.useEffect(() => {
    // Fetch application information
    dispatch(fetchApplicationById(applicationId));
    dispatch(fetchQuoteData({ applicationId }));
  }, [dispatch, applicationId]);

  const [markAsCompleted, setMarkAsCompleted] = React.useState({
    [SECTION_NAMES.COVERAGES]: false,
    [SECTION_NAMES.OPERATIONS]: false,
    [SECTION_NAMES.LOSS_HISTORY]: false,
    [SECTION_NAMES.ADDITIONAL_INFO]: false,
    [SECTION_NAMES.CLASSES_AND_COMMODITIES]: false,
  });

  const { mutate: update } = useMutation(updateSectionCompletionMap, {
    onSuccess: () => {
      dispatch(fetchApplicationById(applicationId)).then(() => {
        resetLoading();
      });
    },
    onError: () => {
      enqueueSnackbar('Error updating section completion map', {
        variant: 'error',
      });
      dispatch(fetchApplicationById(applicationId));
    },
  });

  React.useEffect(() => {
    // check if the application has all the files
    const hasEquipmentFiles =
      application.activeApplication?.indicationForm?.operationsForm
        ?.equipmentList.flatfileMetadata?.fileMetadata.name;
    const hasDriverFiles =
      application.activeApplication?.additionalInfoForm?.driverList;
    const hasLossRunFiles =
      application.activeApplication?.additionalInfoForm?.lossRunFiles;

    if (
      hasEquipmentFiles &&
      hasDriverFiles &&
      hasLossRunFiles &&
      activeStep === 0
    ) {
      setActiveStep(1);
    }

    if (!hasEquipmentFiles && activeStep === 1) {
      setActiveStep(0);
    }
  }, [activeStep, application]);

  React.useEffect(() => {
    dispatch(removeActiveApplication());
    setActiveStep(0);

    return () => {
      dispatch(removeActiveApplication());
      setActiveStep(0);
    };
  }, [dispatch]);

  const { mutate: mutateFiles } = useMutation(updateFiles, {
    onSuccess: () => {
      dispatch(removeActiveApplication());
      dispatch(fetchApplicationById(applicationId));
      setActiveStep(activeStep + 1);
    },
  });

  const { mutate: submitApplication } = useMutation(submitRenewalApplication, {
    onSuccess: () => {
      setActiveStep(activeStep + 1);
      localStorage.removeItem(`unsavedRenewal|${applicationId}`);
    },
  });

  const methods = useForm({
    defaultValues: {},
    mode: 'onChange',
  });

  React.useEffect(() => {
    intercomUpdate({
      verticalPadding: 85,
    });

    return () => {
      dispatch(resetActiveApplication());

      intercomUpdate({
        verticalPadding: 0,
      });
    };
  }, [dispatch, intercomUpdate]);

  const handleMarkAsCompleted = useMemo(
    () => (section: string, value: boolean) => {
      setLoading(section);

      update({
        applicationID: applicationId,
        sectionCompletionMap: {
          ...markAsCompleted,
          [section]: value,
        } as any,
      });
    },
    [applicationId, markAsCompleted, update],
  );

  useEffect(() => {
    if (details?.summary.renewalMetadata?.SectionCompletionMap) {
      setMarkAsCompleted({
        ...details.summary.renewalMetadata.SectionCompletionMap,
      });
    }
  }, [details?.summary.renewalMetadata?.SectionCompletionMap]);

  const getCompletedSteps = React.useMemo(
    () => Object.values(markAsCompleted).filter((value) => value).length,
    [markAsCompleted],
  );

  React.useEffect(() => {
    if (details && !details?.summary.renewalMetadata?.originalApplicationId) {
      navigate('/applications/list');
    }
  }, [
    details,
    details?.summary.renewalMetadata?.originalApplicationId,
    navigate,
  ]);

  const steps = React.useMemo(() => {
    return [
      {
        label: 'Upload updated documents',
        key: 'uploadDocuments',
        component: <ApplicationRenewStep1 />,
      },
      {
        label: (
          <div>
            Complete all sections:{' '}
            <span className="font-semibold">{getCompletedSteps}</span> of{' '}
            <span className="font-semibold">5</span>
          </div>
        ),
        key: 'completeSections',
        component: (
          <ApplicationRenewStep2
            markAsCompleted={markAsCompleted}
            handleMarkAsCompleted={handleMarkAsCompleted}
            state={state}
          />
        ),
      },
      {
        label: 'Submit application',
        key: 'submitApplication',
        component: (
          <Box flex={1} className="flex items-center justify-center">
            <Complete
              title="Congratulations! Your renewal application is submitted"
              buttonLabel="Back to Applications"
              buttonUrl="/applications/list"
            />
          </Box>
        ),
      },
    ];
  }, [getCompletedSteps, markAsCompleted, state, handleMarkAsCompleted]);

  const onSubmit = () => {
    if (steps[activeStep].key === 'uploadDocuments') {
      mutateFiles({
        applicationID: applicationId,
        files: {
          driverList: methods.getValues('driverList'),
          equipmentList: methods.getValues('equipmentList'),
          lossRunFiles: methods.getValues('lossRunFiles'),
          iftaFiles: methods.getValues('iftaFiles'),
        },
      });
    } else if (steps[activeStep].key === 'completeSections') {
      submitApplication({
        applicationID: applicationId,
      });
    }
  };

  const beforeUnloadEventHandler = React.useCallback(() => {
    if (isApplicationPersistenceEnabled && !_.isEmpty(methods.getValues())) {
      localStorage.setItem(
        `unsavedRenewal|${applicationId}`,
        JSON.stringify({
          currentStep: steps[activeStep].key,
          timeStamp: Date.now(),
          data: methods.getValues(),
        }),
      );
    }
  }, [applicationId, activeStep, isApplicationPersistenceEnabled]);

  useBeforeUnload(() => {
    beforeUnloadEventHandler();
  });

  React.useEffect(() => {
    if (isApplicationPersistenceEnabled) {
      try {
        const savedApplication = JSON.parse(
          localStorage.getItem(`unsavedRenewal|${applicationId}`) || '',
        ) || { data: {} };

        if (savedApplication?.currentStep === steps[activeStep].key) {
          methods.reset(savedApplication.data);
        }
      } catch (ex) {
        // Ignore error and do not populate anything
      }
    }

    return () => {
      beforeUnloadEventHandler();
    };
  }, [isApplicationPersistenceEnabled]);

  const preSubmit = () => {
    methods.handleSubmit(onSubmit)();
  };

  if (!isRenewalsFlowEnabled) {
    navigate('/applications/list', { replace: true });
  }

  if (details?.summary && isStateNotProgress(details?.summary)) {
    navigate(`/applications/${applicationId}/renew/submitted`);

    return null;
  }

  if (
    details?.summary.state === ApplicationState.ApplicationStateQuoteGenerated
  ) {
    navigate(`/applications/${applicationId}/quote`);

    return null;
  }

  if (!details) {
    return (
      <div className="flex items-center justify-center w-full h-full">
        <CircularProgress />
      </div>
    );
  }

  if (activeStep === 2) {
    return <>{steps[activeStep].component}</>;
  }

  return (
    <>
      <ApplicationHeader
        details={details || ({} as ApplicationDetail)}
        onBack={() => {
          navigate('/applications/list', { replace: true });
        }}
      />
      <Container
        maxWidth="md"
        classes={{
          root: classes.renewalContainer,
        }}
      >
        <Show when={activeStep !== 2}>
          <Grid alignItems="center" container direction="row">
            <Grid item xs={6}>
              <Typography
                variant="h4"
                fontWeight="fontWeightBold"
                color="textPrimary"
                gutterBottom
              >
                Renewal Application
              </Typography>
              <FormHelperText className={classes.formHelperText}>
                Please update the customer&apos;s information and submit to get
                a renewal quote
              </FormHelperText>
            </Grid>
            <Grid item xs={6}>
              <HorizontalStepper
                steps={steps}
                activeStep={activeStep}
                onChange={() => null}
              />
            </Grid>
          </Grid>
        </Show>

        <FormProvider {...methods}>
          <div key={steps[activeStep].key}>{steps[activeStep].component}</div>
        </FormProvider>

        <Box py="32px" />

        <AppBar
          classes={{ root: classes.footerToolbar }}
          color="inherit"
          elevation={0}
          position="fixed"
          sx={{ top: 'auto', bottom: 0 }}
        >
          <Container>
            {steps[activeStep].key !== 'submitApplication' && (
              <Toolbar>
                <Grid
                  item
                  container
                  alignItems="flex-end"
                  justifyContent="flex-end"
                >
                  <Box
                    display="flex"
                    alignItems="center"
                    justifyContent="flex-end"
                  >
                    <Button
                      endIcon={<ArrowForwardIosRoundedIcon />}
                      variant="contained"
                      onClick={preSubmit}
                      disabled={
                        steps[activeStep].key === 'completeSections' &&
                        getCompletedSteps !==
                          Object.keys(markAsCompleted).length
                      }
                    >
                      {steps[activeStep].key === 'completeSections'
                        ? 'Submit Application'
                        : 'Proceed'}
                    </Button>
                  </Box>
                </Grid>
              </Toolbar>
            )}
          </Container>
        </AppBar>
      </Container>
    </>
  );
};

export default ApplicationRenewCreate;
