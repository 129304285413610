import { Button } from '@material-ui/core';
import { useQuery } from '@tanstack/react-query';
import clsx from 'clsx';
import { useEffect, useMemo, useState } from 'react';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';

import AddIcon from 'src/assets/icons/add.svg?react';

import BusinessOwnerDetail from './components/business-owner-detail';
import CreateEndorsementDialog from './components/create-endorsement-dialog';
import EndorsementHeader from './components/endorsement-header';
import EndorsementTab from './components/endorsement-tab';
import PolicyTab from './components/policy-tab';
import SummaryCard from './components/summary-card';
import { fetchPolicyDetail } from './queries/policy';

const tabs = [
  { label: 'Policy', value: 'policy' },
  { label: 'Endorsements', value: 'endorsements' },
  { label: 'Billing', value: 'billing' },
];

export default function Policy() {
  const { policyId = '' } = useParams();
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();
  const [currentTab, setCurrentTab] = useState(
    searchParams.get('tab') || 'policy',
  );

  const [isCreateEndorsementDialogOpen, setIsCreateEndorsementDialogOpen] =
    useState(false);

  const { data } = useQuery(['policy-detail', policyId], () =>
    fetchPolicyDetail(policyId),
  );

  const miscellaneousData = useMemo(() => {
    const formatted: any = [];

    if (data?.additionalInsureds?.length) {
      data.additionalInsureds.forEach((record) => {
        formatted.push({
          field: 'Additional Insured',
          value: (
            <>
              <p>{record.name}</p>
              <p>
                {[
                  record?.address?.street,
                  record?.address?.city,
                  record?.address?.state,
                  record?.address?.zip,
                ]
                  .filter((v) => !!v)
                  .join(', ')}
              </p>
            </>
          ),
        });
      });
    }

    return formatted;
  }, [data]);

  useEffect(() => {
    setSearchParams({ tab: currentTab });
  }, [currentTab, setSearchParams]);

  useEffect(() => {
    // Scroll to top on page load
    window.scrollTo(0, 0);
  }, []);

  return (
    <section className="relative">
      <EndorsementHeader
        title={`#${data?.policyNumber.replace(/^\D+/g, '')}`}
        underwriterName={`${data?.underwriter?.firstName} ${data?.underwriter?.lastName}`}
        underwriterEmail={data?.underwriter?.email}
        onBack={() => navigate('/policies/list', { replace: true })}
      />
      <div className="flex justify-center px-10 py-16">
        <div className="w-full 2xl:max-w-7xl">
          <section className="flex flex-row items-center mb-6 space-x-3 space-y-3">
            <h1 className="text-[28px] font-bold flex-1">
              {data?.companyName}
            </h1>
            <Button
              startIcon={<AddIcon />}
              variant="contained"
              onClick={() => setIsCreateEndorsementDialogOpen(true)}
            >
              Create Endorsement
            </Button>
          </section>

          <div className="flex flex-row">
            <BusinessOwnerDetail data={data} />
            <SummaryCard data={data} />
          </div>

          <div className="flex flex-row mt-6">
            <ul className="sticky flex flex-col w-48 mb-4 top-32 h-max">
              {tabs.map(({ label, value }) => (
                <li
                  key={value}
                  onClick={() => {
                    if (value === 'billing') {
                      window.open('https://dashboard.useascend.com/programs');
                    } else {
                      setCurrentTab(value);
                    }
                  }}
                  className={clsx(
                    'p-3 border-l-4 cursor-pointer text-left',
                    value === currentTab
                      ? 'font-semibold text-primary-main border-primary-main bg-primary-extraLight'
                      : 'border-transparent',
                  )}
                >
                  {label}
                </li>
              ))}
            </ul>
            <div className="flex-1 py-2 pl-8">
              {currentTab === 'policy' && (
                <PolicyTab
                  coverages={data?.coverages}
                  coverageGroups={data?.coverageGroups}
                  drivers={data?.drivers}
                  vehicles={data?.vehicles}
                  miscellaneous={miscellaneousData}
                />
              )}
              {currentTab === 'endorsements' && <EndorsementTab />}
            </div>
          </div>
        </div>
      </div>

      <CreateEndorsementDialog
        open={isCreateEndorsementDialogOpen}
        onClose={() => setIsCreateEndorsementDialogOpen(false)}
        policyId={policyId}
      />
    </section>
  );
}
