import {
  Autocomplete,
  Button,
  FormControl,
  FormHelperText,
  MenuItem,
  Select,
  TextField,
} from '@material-ui/core';
import {
  Checkbox,
  Chip,
  Dialog,
  EMAIL_REGEX,
  InputWithLabel,
} from '@nirvana/ui-kit';
import { startCase } from 'lodash';
import { useSnackbar } from 'notistack';
import { Controller, useForm } from 'react-hook-form-v7';
import { USER_INVITE_NEW_USER_CLICK } from 'src/features/telematics/events';
import { useAnalytics } from 'src/helpers/analytics';
import {
  AgencyRole,
  InviteUserMutationVariables,
  RoleGroupEnum,
  useInviteUserMutation,
} from 'src/types/graphql-types';
import { agencyRoles, roleLabels } from './constants/agencyRoleGroup';

type UserInviteDialogProps = {
  open: boolean;
  onClose: () => void;
  agencyID: string | undefined;
  inviterUserID: string | undefined;
};

type UserInviteRequest = {
  email: string;
  role: RoleGroupEnum;
  sFDCAgencyRoles: string[];
};

const agencyRoleOptions = Object.entries(AgencyRole)?.map(([key, value]) => ({
  value,
  label: startCase(key),
}));

const UserInviteDialog = ({
  open,
  onClose,
  agencyID,
  inviterUserID,
}: UserInviteDialogProps) => {
  const [userInvite] = useInviteUserMutation();
  const { enqueueSnackbar } = useSnackbar();
  const { capture } = useAnalytics();

  const {
    control,
    handleSubmit,
    register,
    getValues,
    setValue,
    formState: { errors },
  } = useForm<UserInviteRequest>({
    defaultValues: {
      email: '',
      role: '' as RoleGroupEnum,
      sFDCAgencyRoles: [],
    },
    mode: 'onSubmit',
  });

  const onSubmit = (data: UserInviteRequest) => {
    capture(USER_INVITE_NEW_USER_CLICK, {
      ...data,
      agencyID,
      inviterUserID,
    });

    const payload: InviteUserMutationVariables = {
      email: data.email,
      role: data.role,
      agencyID,
      sFDCAgencyRoles: data.sFDCAgencyRoles as Array<AgencyRole>,
    };

    userInvite({
      variables: payload,
      onCompleted: () => {
        onClose();
        enqueueSnackbar('Invitation sent successfully', { variant: 'success' });
      },
      onError: () => {
        enqueueSnackbar(
          'Unexpected error while sending the invitation. Please try again later.',
          { variant: 'error' },
        );
      },
    });
    onClose();
  };

  return (
    <Dialog
      open={open}
      onClose={onClose}
      PaperProps={{ style: { width: '40rem', maxHeight: '80vh' } }}
      title={<p className="text-xl font-bold">Invite Members</p>}
    >
      <hr className="-mx-6 -mt-6" />
      <div className="pt-6 space-y-6">
        <div className="space-y-2">
          <p>
            Please enter their information and we will reach out with an email
            invitation.
          </p>
        </div>
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="space-y-2">
            <div className="flex items-center space-x-4">
              <InputWithLabel
                noMargin
                label="Email"
                placeholder="Enter Email"
                formControlProps={{
                  fullWidth: true,
                }}
                {...register('email', {
                  required: 'Please enter an email',
                  pattern: {
                    value: EMAIL_REGEX,
                    message: 'Invalid email address',
                  },
                })}
                error={!!errors.email}
                helperText={(errors?.email?.message as string) ?? ' '}
              />

              <FormControl fullWidth>
                <p className="pb-1 text-xs text-primary-main">
                  System Permissions
                </p>
                <Controller
                  name="role"
                  control={control}
                  render={({ field }) => (
                    <Select
                      {...field}
                      displayEmpty
                      defaultValue=""
                      variant="outlined"
                      renderValue={(selected) =>
                        selected ? (
                          roleLabels[selected as keyof typeof roleLabels]
                        ) : (
                          <span className="text-text-hint">
                            Select System Permissions
                          </span>
                        )
                      }
                      MenuProps={{
                        anchorOrigin: {
                          vertical: 'bottom',
                          horizontal: 'left',
                        },
                        transformOrigin: {
                          vertical: 'top',
                          horizontal: 'left',
                        },
                        getContentAnchorEl: null,
                      }}
                      error={!!errors.role}
                    >
                      {agencyRoles?.map(({ role, description }) => (
                        <MenuItem key={role} value={role}>
                          <div>
                            <p>{roleLabels[role]}</p>
                            <span className="text-xs text-text-hint">
                              {description}
                            </span>
                          </div>
                        </MenuItem>
                      ))}
                    </Select>
                  )}
                  rules={{ required: 'Please select a role' }}
                />
                <FormHelperText error>
                  {errors?.role?.message ?? ' '}
                </FormHelperText>
              </FormControl>
            </div>
            <FormControl fullWidth error={!!errors.sFDCAgencyRoles}>
              <p className="pb-1 text-xs text-primary-main">Role(s)</p>
              <Controller
                name="sFDCAgencyRoles"
                control={control}
                render={({ field }) => (
                  <Autocomplete
                    {...field}
                    multiple
                    disableCloseOnSelect
                    disableClearable
                    options={agencyRoleOptions}
                    value={getValues('sFDCAgencyRoles')
                      ?.map((role) =>
                        agencyRoleOptions.find((opt) => opt.value === role),
                      )
                      ?.filter(
                        (item): item is { value: AgencyRole; label: string } =>
                          !!item,
                      )}
                    onChange={(_, newValue) => {
                      const transformedRoles = newValue?.map(
                        (item) => item.value as AgencyRole,
                      );
                      setValue('sFDCAgencyRoles', transformedRoles);
                    }}
                    renderTags={(
                      value: { value: AgencyRole; label: string }[],
                      getTagProps,
                    ) =>
                      value?.map((option, index) => (
                        <Chip
                          label={<p className="ml-1 mr-3">{option.label}</p>}
                          {...getTagProps({ index })}
                          layout="rounded"
                          key={index}
                        />
                      ))
                    }
                    renderOption={(props, option) => (
                      <li {...props}>
                        <Checkbox
                          checked={getValues('sFDCAgencyRoles').includes(
                            option.value,
                          )}
                        />
                        {option.label}
                      </li>
                    )}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        variant="outlined"
                        placeholder={
                          getValues('sFDCAgencyRoles').length === 0
                            ? 'Please select Role(s)'
                            : ''
                        }
                        error={!!errors.sFDCAgencyRoles}
                      />
                    )}
                    limitTags={3}
                    getLimitTagsText={(more) => (
                      <p className="text-xs text-primary-main">+ {more} More</p>
                    )}
                  />
                )}
                rules={{
                  required: 'Please select at least one role',
                }}
              />

              <FormHelperText error>
                {errors?.sFDCAgencyRoles?.message ?? ' '}
              </FormHelperText>
            </FormControl>

            <FormControl className="flex items-end">
              <Button
                type="submit"
                variant="contained"
                color="primary"
                className="my-4"
              >
                Add
              </Button>
            </FormControl>
          </div>
        </form>
      </div>
    </Dialog>
  );
};

export default UserInviteDialog;
