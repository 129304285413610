import { Switch } from '@nirvana/ui-kit';
import { useParams } from 'react-router-dom';
import { AppConstantsProvider } from 'src/features/admitted/hooks/useConstants';

import { EndorsementType } from '../endorsement-summary/layout';
import DriverUpdate from './drivers';
import EquipmentsUpdate from './equipments';
import MiscellaneousUpdate from './miscellaneous';

const EndorsementUpdate = () => {
  const { endorsementType } = useParams();

  return (
    <AppConstantsProvider>
      <Switch>
        <Switch.Match when={endorsementType === EndorsementType.Coverages}>
          <DriverUpdate />
        </Switch.Match>
        <Switch.Match when={endorsementType === EndorsementType.Drivers}>
          <DriverUpdate />
        </Switch.Match>
        <Switch.Match when={endorsementType === EndorsementType.Equipment}>
          <EquipmentsUpdate />
        </Switch.Match>
        <Switch.Match when={endorsementType === EndorsementType.Miscellaneous}>
          <MiscellaneousUpdate />
        </Switch.Match>
      </Switch>
    </AppConstantsProvider>
  );
};

export default EndorsementUpdate;
