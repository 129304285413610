import {
  AppBar,
  IconButton,
  Toolbar,
  Tooltip,
  Typography,
} from '@material-ui/core';
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';
import { Show } from '@nirvana/ui-kit';
import IconBack from 'src/assets/icons/arrow-left.svg';

export interface EndorsementHeaderProps {
  title: string;
  subtitle?: string; // Optional subtitle (e.g., insured name, DOT)
  underwriterName?: string;
  underwriterEmail?: string;
  onBack: () => void;
}

const EndorsementHeader = ({
  title,
  subtitle,
  underwriterName,
  underwriterEmail,
  onBack,
}: EndorsementHeaderProps) => {
  return (
    <div className="fixed z-50 w-full border-primary-tint3">
      <AppBar
        position="static"
        color="inherit"
        elevation={0}
        className="bg-white shadow-none"
      >
        <Toolbar className="flex justify-between mx-4 min-h-[48px]">
          {/* Left Section */}
          <div className="flex items-center">
            <IconButton onClick={onBack} size="small" className="p-1 mr-2">
              <img src={IconBack} alt="Go Back" />
            </IconButton>
            <Typography
              variant="body2"
              className="text-sm font-semibold text-secondary-main"
            >
              {title}
            </Typography>
          </div>

          {/* Center Section */}
          <Show when={subtitle}>
            <div className="flex items-center space-x-2">
              <Typography
                variant="body2"
                className="text-sm font-medium text-secondary-main"
              >
                {subtitle}
              </Typography>
            </div>
          </Show>

          {/* Right Section */}
          <div className="flex items-center mr-5">
            <Show when={underwriterName}>
              <Typography variant="body2" className="text-sm text-text-hint">
                Underwriter:&nbsp;
              </Typography>
              <Typography
                variant="body2"
                className="text-sm text-secondary-main"
              >
                {underwriterName}
              </Typography>
            </Show>
            <Show when={underwriterEmail}>
              <Tooltip title={underwriterEmail || ''}>
                <InfoOutlinedIcon
                  fontSize="small"
                  className="ml-1 text-blue-500"
                />
              </Tooltip>
            </Show>
          </div>
        </Toolbar>
      </AppBar>
    </div>
  );
};

export default EndorsementHeader;
