import { TableV8 } from '@nirvana/ui-kit';
import { useQuery } from '@tanstack/react-query';
import { useParams } from 'react-router-dom';
import { coverageColumns } from '../../constants/coverage';
import { fetchEndorsementRequestCoverages } from '../../queries/endorsement';
import EditLayout, { EndorsementType } from './layout';

export default function Coverages() {
  const { policyId = '', endorsementId = '' } = useParams();

  const { data } = useQuery(
    ['endorsement-request-coverages', policyId, endorsementId],
    () => fetchEndorsementRequestCoverages(policyId, endorsementId),
    {
      select: (data) => data.coverages,
    },
  );

  return (
    <EditLayout title="Coverages" endorsementType={EndorsementType.Coverages}>
      <TableV8 columns={coverageColumns} data={data ?? []} />
    </EditLayout>
  );
}
