import {
  FormControl,
  FormHelperText,
  MenuItem,
  Select,
  Typography,
} from '@material-ui/core';
import { InputWithLabel, InputZip, constants } from '@nirvana/ui-kit';
import { useMutation } from '@tanstack/react-query';
import { Controller, useFormContext } from 'react-hook-form-v7';
import AddressSearch from 'src/components/address-search';
import { decodeZipInfo } from 'src/features/admitted/queries/application';

const MailingAddress = () => {
  const { mutateAsync } = useMutation(decodeZipInfo);

  const {
    control,
    formState: { errors },
    register,
    setValue,
  } = useFormContext();

  return (
    <div className="flex flex-row items-start">
      <h6 className="font-bold basis-1/3">Mailing Address</h6>
      <div className="grid grid-cols-6 gap-4 basis-4/6">
        <Controller
          control={control}
          defaultValue=""
          name="mailingAddress.street"
          rules={{
            required: 'Please enter street name',
          }}
          render={({ field: { value, onChange } }) => {
            return (
              <FormControl className="col-span-6">
                <FormHelperText className="mt-0 text-primary-main">
                  Address
                </FormHelperText>
                <AddressSearch
                  value={value}
                  onValueChange={onChange}
                  onPlaceSelect={(placeDetails) => {
                    onChange(placeDetails?.street);
                    setValue('mailingAddress.city', placeDetails?.city);
                    setValue('mailingAddress.state', placeDetails?.stateCode);
                    setValue('mailingAddress.zip', placeDetails?.zip);
                  }}
                />

                {!!(errors?.mailingAddress as any)?.street && (
                  <FormHelperText error>
                    {(errors?.mailingAddress as any)?.street?.message}
                  </FormHelperText>
                )}
              </FormControl>
            );
          }}
        />
        <FormControl className="col-span-2">
          <InputWithLabel
            noMargin
            label="City"
            placeholder=""
            formControlProps={{
              fullWidth: true,
            }}
            error={!!(errors?.mailingAddress as any)?.city?.message}
            {...register('mailingAddress.city', {
              required: 'Please enter city',
            })}
          />
        </FormControl>
        <FormControl className="col-span-2" size="small">
          <FormHelperText className="mt-0 text-primary-main">
            State
          </FormHelperText>
          <Controller
            control={control}
            defaultValue=""
            name={'mailingAddress.state'}
            rules={{ required: 'Please select state' }}
            render={({ field: { onChange, value } }) => {
              return (
                <Select
                  size="small"
                  displayEmpty
                  variant="outlined"
                  value={value}
                  onChange={(e) => onChange(e.target.value)}
                  error={!!(errors?.mailingAddress as any)?.state?.message}
                >
                  <MenuItem value="">
                    <Typography color="text.hint">State</Typography>
                  </MenuItem>
                  {constants.usStates.map((record) => (
                    <MenuItem value={record.code} key={record.code}>
                      {record.name}
                    </MenuItem>
                  ))}
                </Select>
              );
            }}
          />
        </FormControl>
        <FormControl className="col-span-2">
          <FormHelperText className="mt-0 text-primary-main">
            Zip
          </FormHelperText>
          <Controller
            control={control}
            defaultValue=""
            name="mailingAddress.zip"
            rules={{
              required: 'Enter zip',
              validate: {
                isExtendedZip: (value) => {
                  return (
                    value.length <= 5 || 'Please remove the zip code extension'
                  );
                },
                isValidZip: async (value) => {
                  try {
                    await mutateAsync(value);

                    return true;
                  } catch (ex) {
                    return 'Please enter a valid ZIP code';
                  }
                },
              },
            }}
            render={({ field: { value, onChange } }) => (
              <InputZip
                placeholder="eg. 12345"
                value={value}
                onChange={(e) => onChange(e.target.value)}
                error={!!(errors?.mailingAddress as any)?.zip?.message}
              />
            )}
          />
        </FormControl>
      </div>
    </div>
  );
};

export default MailingAddress;
