import { TSP, TSPRecord } from '@nirvana/api/quoting';
import indexOf from 'lodash/indexOf';

import { Tsp } from '@nirvana/safety/src/types/graphql-types';
import AzugaLogo from '../assets/icons/vendors/azuga.svg';
import GeotabLogo from '../assets/icons/vendors/geotab.svg';
import JJKellerLogo from '../assets/icons/vendors/jj-keller.svg';
import MotiveLogo from '../assets/icons/vendors/motive.svg';
import OmnitracsLogo from '../assets/icons/vendors/omnitracs.png';
import OmnitracsXrsLogo from '../assets/icons/vendors/omnitracs-xrs.svg';
import SamsaraLogo from '../assets/icons/vendors/samsara.png';
import VerizonConnectRevealLogo from '../assets/icons/vendors/verizon-connect-reveal.svg';
import VerizonConnectFleetLogo from '../assets/icons/vendors/verizon-connect-fleet.svg';
import BigRoadLogo from '../assets/icons/vendors/big-road.png';
import FleetCompleteLogo from '../assets/icons/vendors/fleet-complete.png';
import ZonarLogo from '../assets/icons/vendors/zonar.png';
import GPSTabLogo from '../assets/icons/vendors/gpstab.png';
import MasterELDLogo from '../assets/icons/vendors/mastereld.png';
import MountainELDLogo from '../assets/icons/vendors/mountaineld.png';
import RoadstarELDLogo from '../assets/icons/vendors/roadstar.png';
import TFMELDLogo from '../assets/icons/vendors/tfmeld.png';
import KSKELDLogo from '../assets/icons/vendors/kskeld.png';
import RightTruckingELDLogo from '../assets/icons/vendors/righttruckingeld.png';
import ERoadLogo from '../assets/icons/vendors/eroad.png';
import OptimaELDLogo from '../assets/icons/vendors/optima-eld.png';
import XELDLogo from '../assets/icons/vendors/x-eld.png';
import ProRideELDLogo from '../assets/icons/vendors/pro-ride-eld.png';
import RealELDLogo from '../assets/icons/vendors/real-eld.svg';
import TTELDLogo from '../assets/icons/vendors/tt-eld.svg';
import TransfloLogo from '../assets/icons/vendors/transflo.png';
import GoFleetLogo from '../assets/icons/vendors/gofleet.png';
import GoGPSLogo from '../assets/icons/vendors/gogps.png';
import AgilisisLinxupLogo from '../assets/icons/vendors/linxup.png';
import ATTFleetLogo from '../assets/icons/vendors/attfleet.png';
import ContiGoLogo from '../assets/icons/vendors/conti-go.png';
import EnVueLogo from '../assets/icons/vendors/enVue.png';
import FleetisticsLogo from '../assets/icons/vendors/fleetistics.png';
import EvoELDLogo from '../assets/icons/vendors/evoeld.svg';
import ZippyELDLogo from '../assets/icons/vendors/zippyEld.svg';
import IDELDLogo from '../assets/icons/vendors/IDELD-Logo.png';

export const isOAuthSupported = (provider: TSP) => {
  return [TSP.TspSamsara, TSP.TspKeepTruckin].includes(provider);
};

export const isLogoAvailable = (provider: TSP) => {
  return [
    TSP.TspSamsara,
    TSP.TspKeepTruckin,
    TSP.TspGeotab,
    TSP.TspVerizonConnectReveal,
    TSP.TspVerizonConnectFleet,
    TSP.TspBigRoad,
    TSP.TspFleetComplete,
    TSP.TspgpsTab,
  ].includes(provider);
};

export const isPopularTSP = (provider: TSP) => {
  return [
    TSP.TspAgilisLinxup,
    TSP.TspAzuga,
    TSP.TspBigRoad,
    TSP.Tsperoad,
    TSP.TspeldOne,
    TSP.TspGeotab,
    TSP.TspGorillaSafety,
    TSP.TspgpsTab,
    TSP.TspKeepTruckin,
    TSP.TspOmnitracs,
    TSP.TspOmnitracsXrs,
    TSP.TspRandMcNally,
    TSP.TspSamsara,
    TSP.TspVerizonConnectReveal,
    TSP.TspVerizonConnectFleet,
    TSP.TspWebfleet,
  ].includes(provider);
};

export interface ProviderInfo {
  name: string;
  logo?: any;
}

export const getProviderInfo = (provider: TSP | string): ProviderInfo => {
  switch (provider) {
    case TSP.TspSamsara:
    case Tsp.TspSamsaraSafety:
      return {
        name: 'Samsara',
        logo: SamsaraLogo,
      };
    case TSP.TspGeotab:
      return {
        name: 'GeoTab',
        logo: GeotabLogo,
      };
    case TSP.TspKeepTruckin:
    case Tsp.TspKeepTruckinSafety:
      return {
        name: 'Motive',
        logo: MotiveLogo,
      };
    case TSP.TspOmnitracs:
      return {
        name: 'Omnitracs',
        logo: OmnitracsLogo,
      };
    case TSP.TspOmnitracsXrs:
      return {
        name: 'Omnitracs XRS',
        logo: OmnitracsXrsLogo,
      };
    case TSP.TspVerizonConnectReveal:
      return {
        name: 'Verizon Connect Reveal',
        logo: VerizonConnectRevealLogo,
      };
    case TSP.TspVerizonConnectFleet:
      return {
        name: 'Verizon Connect Fleet',
        logo: VerizonConnectFleetLogo,
      };
    case TSP.TspAzuga:
      return {
        name: 'Azuga',
        logo: AzugaLogo,
      };
    case TSP.TspjjKeller:
      return {
        name: 'JJ Keller',
        logo: JJKellerLogo,
      };
    case TSP.TspBigRoad:
      return {
        name: 'BigRoad',
        logo: BigRoadLogo,
      };
    case TSP.TspFleetComplete:
      return {
        name: 'Fleet Complete HUB',
        logo: FleetCompleteLogo,
      };
    case TSP.TspZonar:
      return {
        name: 'Zonar Systems',
        logo: ZonarLogo,
      };
    case TSP.TspgpsTab:
      return {
        name: 'GPSTab',
        logo: GPSTabLogo,
      };
    case TSP.Tspkskeld:
      return {
        name: 'KSK ELD',
        logo: KSKELDLogo,
      };
    case TSP.TspMasterEld:
      return {
        name: 'Master ELD',
        logo: MasterELDLogo,
      };
    case TSP.TspMountainEld:
      return {
        name: 'Mountain ELD',
        logo: MountainELDLogo,
      };
    case TSP.TspRoadStarEld:
      return {
        name: 'Roadstar ELD',
        logo: RoadstarELDLogo,
      };
    case TSP.Tsptfmeld:
      return {
        name: 'TFM ELD',
        logo: TFMELDLogo,
      };
    case TSP.TspRightTruckingEld:
      return {
        name: 'Right Trucking ELD',
        logo: RightTruckingELDLogo,
      };
    case TSP.Tsperoad:
      return {
        name: 'ERoad',
        logo: ERoadLogo,
      };
    case TSP.TspOptimaEld:
      return {
        name: 'Optima ELD',
        logo: OptimaELDLogo,
      };
    case TSP.Tspxeld:
      return {
        name: 'X ELD',
        logo: XELDLogo,
      };
    case TSP.TspProRideEld:
      return {
        name: 'Pro Ride ELD',
        logo: ProRideELDLogo,
      };
    case TSP.TspRealEld:
      return {
        name: 'Real ELD',
        logo: RealELDLogo,
      };
    case TSP.Tsptteld:
      return {
        name: 'TT ELD',
        logo: TTELDLogo,
      };
    case TSP.TspTransflo:
      return {
        name: 'Transflo',
        logo: TransfloLogo,
      };
    case TSP.TspgoFleet:
      return {
        name: 'Go Fleet',
        logo: GoFleetLogo,
      };
    case TSP.TspgoGps:
      return {
        name: 'Go GPS',
        logo: GoGPSLogo,
      };
    case TSP.TspAgilisLinxup:
      return {
        name: 'Agilisis Linxup',
        logo: AgilisisLinxupLogo,
      };
    case TSP.TspatAndTFleet:
      return {
        name: 'AT&T Fleet Management',
        logo: ATTFleetLogo,
      };
    case TSP.TspFleetistics:
      return {
        name: 'Fleetistics',
        logo: FleetisticsLogo,
      };
    case TSP.TspenVueTelematics:
      return {
        name: 'EnVue',
        logo: EnVueLogo,
      };
    case TSP.TspContiGo:
      return {
        name: 'Conti-GO',
        logo: ContiGoLogo,
      };
    case TSP.Tspevoeld:
      return {
        name: 'Evo ELD',
        logo: EvoELDLogo,
      };
    case TSP.TspZippyEld:
      return {
        name: 'Zippy ELD',
        logo: ZippyELDLogo,
      };
    case TSP.Tspideld:
      return {
        name: 'ID ELD',
        logo: IDELDLogo,
      };

    default:
      return {
        name: provider.replace('TSP', ''),
      };
  }
};

export function customTelematicsSorter(item: TSPRecord) {
  const tspOrderMap = [TSP.TspSamsara, TSP.TspKeepTruckin, TSP.TspGeotab];

  const tsp = item.tsp;
  const index = indexOf(tspOrderMap, tsp);
  return index === -1 ? Infinity : index;
}

const reverseStringEnum = <T extends Record<keyof T, string>>(e: T) =>
  Object.fromEntries(Object.entries(e).map(([k, v]) => [v, k])) as {
    [K in keyof T as T[K]]: K;
  };

export const getProviderInfoFromSafety = (provider: Tsp) => {
  const TspKeys = reverseStringEnum(Tsp);

  const tspHasKey = Object.keys(TSP).some((el) => TspKeys[provider] === el);

  if (!tspHasKey) {
    return getProviderInfo(provider);
  }

  const tspKey = TspKeys[provider as keyof typeof TspKeys];
  return getProviderInfo(TSP[tspKey as keyof typeof TSP]);
};
