import { Button } from '@material-ui/core';
import React, { useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import PencilIcon from 'src/assets/icons/pencil.svg';

export enum EndorsementType {
  'Drivers' = 'drivers',
  'Vehicles' = 'vehicles',
  'Equipment' = 'equipment',
  'Coverages' = 'coverages',
  'Miscellaneous' = 'miscellaneous',
}

interface EditLayoutProps {
  title: string;
  children: React.ReactNode;
  endorsementType: EndorsementType;
}

const EditLayout = ({ title, children, endorsementType }: EditLayoutProps) => {
  const navigate = useNavigate();
  const { policyId, endorsementId } = useParams();

  const handleUpdate = () => {
    navigate(
      `/policies/${policyId}/endorsement/${endorsementId}/update/${endorsementType}`,
    );
  };

  useEffect(() => {
    // Scroll to top on page load
    window.scrollTo(0, 0);
  }, []);

  return (
    <section className="mb-5">
      <div className="flex items-center justify-between mb-1">
        <h2 className="text-base font-bold text-black">{title}</h2>
        {(endorsementType === EndorsementType.Drivers ||
          endorsementType === EndorsementType.Equipment ||
          endorsementType === EndorsementType.Miscellaneous) && (
          <Button
            type="button"
            onClick={handleUpdate}
            startIcon={<img src={PencilIcon} alt={`Update ${title}`} />}
          >
            Update
          </Button>
        )}
      </div>
      <div className="overflow-hidden bg-white border rounded-lg border-text-disabled">
        {children}
      </div>
    </section>
  );
};

export default EditLayout;
