import {
  Autocomplete,
  Button,
  FormControl,
  FormControlLabel,
  FormHelperText,
  Radio,
  RadioGroup,
  TextField,
} from '@material-ui/core';
import {
  Checkbox,
  Chip,
  Dialog,
  InputWithLabel,
  storage,
} from '@nirvana/ui-kit';
import { usStates } from '@nirvana/ui-kit/src/constants';
import { startCase } from 'lodash';
import { useSnackbar } from 'notistack';
import { useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form-v7';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { ONBOARDING_AGENCY_INFO_FLAG } from 'src/constants';
import { useAnalytics } from 'src/helpers/analytics';
import {
  AgencyRole,
  useUpdateAgentDetailMutation,
} from 'src/types/graphql-types';
import { initSelector } from '../init/slices';
import {
  USER_INVITE_AGENCY_INFO_MODAL_CLOSE_CLICK,
  USER_INVITE_AGENCY_INFO_MODAL_SUBMIT_CLICK,
  USER_INVITE_AGENCY_INFO_MODAL_VIEW,
} from '../telematics/events';

type UserDetailsUpdate = {
  title: string;
  phoneNumber: string;
  officeDepartmentRegion: string;
  preferredStates: string[];
  workLocation: string;
  sFDCAgencyRoles: string[];
};

const AgencyInfoModal = () => {
  const [updateAgent] = useUpdateAgentDetailMutation();
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const init = useSelector(initSelector);
  const { user } = init;
  const { capture } = useAnalytics();
  const [agencyInfoModal, setAgencyInfoModal] = useState(true);

  // Determine if the user has the "AgencyServiceMemberRole"
  const isAgencyServiceMemberRole = user?.roles?.agencyRoles?.some(
    (role) => role.role === 'AgencyServiceMemberRole',
  );
  // Redirect based on role
  const redirectPath = isAgencyServiceMemberRole
    ? '/policies/list'
    : '/applications/list';

  const {
    control,
    handleSubmit,
    register,
    getValues,
    setValue,
    formState: { errors },
  } = useForm<UserDetailsUpdate>({
    defaultValues: {
      title: '',
      phoneNumber: '',
      officeDepartmentRegion: '',
      preferredStates: [],
      workLocation: '',
      sFDCAgencyRoles: [],
    },
    mode: 'onSubmit',
  });

  const agencyRoleOptions = Object.entries(AgencyRole)?.map(([key, value]) => ({
    value,
    label: startCase(key),
  }));

  const onSubmit = (data: UserDetailsUpdate) => {
    const userDetailsData = {
      ...data,
      sFDCAgencyRoles: data.sFDCAgencyRoles.map((role) => role as AgencyRole),
    };

    capture(USER_INVITE_AGENCY_INFO_MODAL_SUBMIT_CLICK, { email: user?.email });

    updateAgent({
      variables: userDetailsData,
      onCompleted: () => {
        enqueueSnackbar('Details Updated', { variant: 'success' });
        navigate(redirectPath);
      },
      onError: () => {
        enqueueSnackbar(
          'Unexpected error while updating details. Please try again later.',
          { variant: 'error' },
        );
      },
    });
  };

  useEffect(() => {
    capture(USER_INVITE_AGENCY_INFO_MODAL_VIEW, { email: user?.email });
  }, []);

  const handleClose = () => {
    capture(USER_INVITE_AGENCY_INFO_MODAL_CLOSE_CLICK, { email: user?.email });

    storage.set(ONBOARDING_AGENCY_INFO_FLAG, 'true');
    setAgencyInfoModal(false);
    navigate(redirectPath);
  };

  return (
    <Dialog
      open={agencyInfoModal}
      onClose={handleClose}
      title={<p className="text-sm font-bold">Agency Info</p>}
    >
      <div className="space-y-6 pl-2 w-[430px]">
        <hr className="-mx-8 -mt-6" />
        <div className="space-y-4">
          <div className="space-y-1">
            <p className="text-xl font-bold">Welcome to the Agent Platform!</p>
            <p className="text-sm">
              Complete your profile to get started. <br />
              This helps our team customize outreach according to your book.
            </p>
          </div>
          <form onSubmit={handleSubmit(onSubmit)}>
            <div className="mb-6 space-y-3">
              <FormControl fullWidth error={!!errors.title}>
                <InputWithLabel
                  noMargin
                  label="Title/ Role"
                  placeholder=""
                  formControlProps={{
                    fullWidth: true,
                  }}
                  {...register('title', {
                    required: 'Please enter title',
                  })}
                  error={!!errors.title}
                  helperText={(errors?.title?.message as string) ?? ' '}
                />
              </FormControl>
              <FormControl fullWidth error={!!errors.phoneNumber}>
                <InputWithLabel
                  noMargin
                  label="Mobile Number"
                  placeholder=""
                  formControlProps={{
                    fullWidth: true,
                  }}
                  {...register('phoneNumber', {
                    required: 'Please enter mobile number',
                  })}
                  error={!!errors.phoneNumber}
                  helperText={(errors?.phoneNumber?.message as string) ?? ' '}
                />
              </FormControl>
              <FormControl fullWidth error={!!errors.officeDepartmentRegion}>
                <InputWithLabel
                  noMargin
                  label="Office Name/ Department/ Region"
                  placeholder=""
                  formControlProps={{
                    fullWidth: true,
                  }}
                  {...register('officeDepartmentRegion', {
                    required: 'Please enter office or department or region',
                  })}
                  error={!!errors.officeDepartmentRegion}
                  helperText={
                    (errors?.officeDepartmentRegion?.message as string) ?? ' '
                  }
                />
              </FormControl>
              <FormControl fullWidth error={!!errors.sFDCAgencyRoles}>
                <p className="pb-1 text-xs text-primary-main">Roles</p>
                <Controller
                  name="sFDCAgencyRoles"
                  control={control}
                  render={({ field }) => (
                    <Autocomplete
                      {...field}
                      multiple
                      disableCloseOnSelect
                      disableClearable
                      options={agencyRoleOptions}
                      value={getValues('sFDCAgencyRoles')
                        ?.map((role) =>
                          agencyRoleOptions.find((opt) => opt.value === role),
                        )
                        ?.filter(
                          (
                            item,
                          ): item is { value: AgencyRole; label: string } =>
                            !!item,
                        )}
                      onChange={(_, newValue) => {
                        setValue(
                          'sFDCAgencyRoles',
                          newValue?.map((item) => item.value),
                        );
                      }}
                      renderTags={(
                        value: { value: AgencyRole; label: string }[],
                        getTagProps,
                      ) =>
                        value?.map((option, index) => (
                          <Chip
                            label={<p className="ml-1 mr-3">{option.label}</p>}
                            {...getTagProps({ index })}
                            layout="rounded"
                            key={index}
                          />
                        ))
                      }
                      renderOption={(props, option) => (
                        <li {...props}>
                          <Checkbox
                            checked={getValues('sFDCAgencyRoles').includes(
                              option.value,
                            )}
                          />
                          {option.label}
                        </li>
                      )}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          variant="outlined"
                          placeholder={
                            getValues('sFDCAgencyRoles').length === 0
                              ? 'Please select Roles'
                              : ''
                          }
                          error={!!errors.sFDCAgencyRoles}
                        />
                      )}
                      limitTags={3}
                      getLimitTagsText={(more) => (
                        <p className="text-xs text-primary-main">
                          + {more} More
                        </p>
                      )}
                    />
                  )}
                  rules={{
                    required: 'Please select at least one additional role',
                  }}
                />
                <FormHelperText error>
                  {errors?.sFDCAgencyRoles?.message ?? ' '}
                </FormHelperText>
              </FormControl>

              <FormControl fullWidth>
                <p className="pb-1 text-xs text-primary-main">
                  Which states do you write in most?
                </p>
                <Controller
                  name="preferredStates"
                  control={control}
                  render={({ field }) => (
                    <Autocomplete
                      {...field}
                      multiple
                      disableCloseOnSelect
                      disableClearable
                      options={usStates?.map((state) => state.name)}
                      value={getValues('preferredStates')}
                      onChange={(_, newValue) => {
                        setValue('preferredStates', newValue);
                      }}
                      renderTags={(value: string[], getTagProps) =>
                        value?.map((option, index) => (
                          <Chip
                            label={<p className="ml-1 mr-3">{option}</p>}
                            {...getTagProps({ index })}
                            layout="rounded"
                            key={index}
                          />
                        ))
                      }
                      renderOption={(props, option) => (
                        <li {...props}>
                          <Checkbox
                            checked={getValues('preferredStates').includes(
                              option,
                            )}
                          />
                          {option}
                        </li>
                      )}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          variant="outlined"
                          error={!!errors.preferredStates}
                        />
                      )}
                      limitTags={2}
                      getLimitTagsText={(more) => (
                        <p className="text-xs text-primary-main">
                          + {more} More
                        </p>
                      )}
                    />
                  )}
                  rules={{ required: 'Please select at least one state' }}
                />
                <FormHelperText error>
                  {errors?.preferredStates?.message ?? ' '}
                </FormHelperText>
              </FormControl>
              <FormControl component="fieldset" error={!!errors.workLocation}>
                <p className="pb-1 text-xs text-primary-main">Work Location</p>
                <Controller
                  name="workLocation"
                  control={control}
                  render={({ field }) => (
                    <RadioGroup
                      {...field}
                      onChange={(e) => setValue('workLocation', e.target.value)}
                      value={getValues('workLocation')}
                      row
                    >
                      <FormControlLabel
                        value="Remote"
                        control={<Radio />}
                        label="Remote"
                      />
                      <FormControlLabel
                        value="Office"
                        control={<Radio />}
                        label="Office"
                      />
                    </RadioGroup>
                  )}
                  rules={{ required: 'Please select a work location' }}
                />
                <FormHelperText>
                  {errors?.workLocation?.message ?? ' '}
                </FormHelperText>
              </FormControl>
              <FormControl className="flex items-end">
                <Button type="submit" variant="contained" color="primary">
                  Submit
                </Button>
              </FormControl>
            </div>
          </form>
        </div>
      </div>
    </Dialog>
  );
};

export default AgencyInfoModal;
