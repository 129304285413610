import { useMemo, useState } from 'react';
import { useSearchParams } from 'react-router-dom';

import {
  Coverage,
  CoverageGroup1,
  Driver,
  Vehicle,
  VehicleType,
} from '@nirvana/api/endorsementapp';
import { Show, TableV8 } from '@nirvana/ui-kit';

import { getDriversColumns } from '../constants/driver';
import { getVehicleColumns } from '../constants/vehicle';
import {
  getMiscellaneousColumns,
  MiscellaneousData,
} from '../constants/miscellaneous';
import { getDescriptionByCoverage } from '../utils';
import CoverageModal from './coverage-modal';

type PolicyTabProps = {
  coverages?: Coverage[];
  coverageGroups?: CoverageGroup1[];
  drivers?: Driver[];
  vehicles?: Vehicle[];
  miscellaneous?: MiscellaneousData[];
};

export default function PolicyTab({
  coverages,
  coverageGroups,
  drivers,
  vehicles,
  miscellaneous,
}: PolicyTabProps) {
  const [searchParams] = useSearchParams();

  const [isModalOpen, setModalOpen] = useState(false);

  const handleModalOpen = () => {
    setModalOpen(true);
  };

  const handleModalClose = () => {
    setModalOpen(false);
  };

  const sortedVehicles = useMemo(() => {
    return vehicles?.sort((a, b) => {
      // Sort VehicleType.Trailer to the end
      // Also, sort by vin within the same vehicle type
      if (a.vehicleType === VehicleType.Trailer) {
        return 1;
      }

      if (b.vehicleType === VehicleType.Trailer) {
        return -1;
      }

      return a.vin.localeCompare(b.vin);
    });
  }, [vehicles]);

  const vehicleCountByType = useMemo(() => {
    const countByType = vehicles?.reduce(
      (acc, vehicle) => {
        const { vehicleType } = vehicle;

        if (vehicleType === VehicleType.Trailer) {
          acc.trailers += 1;
        } else {
          acc.powerUnits += 1;
        }

        return acc;
      },
      { trailers: 0, powerUnits: 0 },
    );

    return countByType;
  }, [vehicles]);

  return (
    <Show when={searchParams.get('tab') === 'policy'}>
      <h2 className="mb-5 text-xl font-medium">Coverage</h2>

      <div className="grid grid-cols-2 gap-6 mb-5">
        {coverages?.map(({ name, limits, deductible }) => (
          <div
            key={name}
            className="grid grid-cols-2 gap-4 p-4 bg-white border rounded-lg border-text-disabled"
          >
            <div className="min-h-[9rem]">
              <p className="mb-1 font-bold">{name}</p>
              <p className="text-xs text-text-hint">
                {getDescriptionByCoverage(name)}
              </p>
            </div>

            <div>
              <div className="mb-3">
                <p className="mb-1 text-xs text-text-hint">Limit</p>
                <p className="font-medium whitespace-pre-wrap">
                  {limits ? limits.join('\n') : 'NA'}
                </p>
              </div>

              <div>
                <p className="mb-1 text-xs text-text-hint">Deductible</p>
                <p className="font-medium">{deductible || 'NA'}</p>
              </div>
            </div>
          </div>
        ))}
      </div>

      <div className="flex justify-start mb-2">
        <a
          className="py-5 text-sm font-bold text-primary-main"
          href="#"
          onClick={handleModalOpen}
        >
          View Details
        </a>
      </div>

      <div className="flex items-center mb-5">
        <h2 className="flex-1 text-xl font-medium">Vehicles</h2>
        <span>
          #{vehicleCountByType?.powerUnits} Power Units, #
          {vehicleCountByType?.trailers} Trailers
        </span>
      </div>

      <div className="mb-5 overflow-hidden bg-white border rounded-lg border-text-disabled">
        <TableV8 columns={getVehicleColumns({})} data={sortedVehicles ?? []} />
      </div>

      <div className="flex items-center mb-5">
        <h2 className="flex-1 text-xl font-medium">Drivers</h2>
        <span>#{drivers?.length} Drivers</span>
      </div>

      <div className="mb-5 overflow-hidden bg-white border rounded-lg border-text-disabled">
        <TableV8 columns={getDriversColumns({})} data={drivers ?? []} />
      </div>

      <Show when={!!miscellaneous?.length}>
        <h2 className="mb-5 text-xl font-medium">Miscellaneous</h2>

        <div className="mb-5 overflow-hidden bg-white border rounded-lg border-text-disabled">
          <TableV8
            columns={getMiscellaneousColumns()}
            data={miscellaneous ?? []}
          />
        </div>
      </Show>

      <CoverageModal
        open={isModalOpen}
        onClose={handleModalClose}
        coverageGroups={coverageGroups}
      />
    </Show>
  );
}
